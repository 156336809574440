import React, { useState, useRef, useEffect } from "react";
import {Routes, Route, useNavigate} from 'react-router-dom';
import { useMsal } from "@azure/msal-react";
import { nanoid } from "nanoid";

import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { protectedResources } from "../authConfig";

import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import { Input, Table, Row, Col } from 'reactstrap';
import date from 'date-and-time'; 

function usePrevious(value) {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
}

export const RecentRequestsView = (props) => {
    const { instance } = useMsal();
    const account = instance.getActiveAccount();

    const { error, execute } = useFetchWithMsal({
        scopes: protectedResources.apiRecentRequests.scopes.write
    });

    const [requests, setRequests] = useState(props.requestData);
    const navigate = useNavigate();


    let activeAccount;
    let fs_list = [];

    if (instance) {
        activeAccount = instance.getActiveAccount();
        let ext_fs = activeAccount.idTokenClaims.extension_fs;

        if (ext_fs !== null)
            fs_list = ext_fs.split(' ');
    }

    const handleClickItem = (id) => {
        console.log('handleClickItem', id);
        navigate('/service-request/'+id);
    }

    const requestList = requests.length > 0 ? (
        requests.map(request => (

            <RequestItem
                id={request.ClientRequestId}
                ClientName={request.ClientName}
                RequestBy={request.RequestBy}
                Category={request.Category}
                TaskType={request.TaskType}
                Subject={request.Subject}
                RequestStatus={request.Status}
                RequestDate={ date.format(new Date(request.RequestDate), 'M/D/YY', false) }
                key={request.ClientRequestId}
            />

            // <tr className='cursor-pointer' key={request.ClientRequestId} onClick={() => handleClickItem(request.ClientRequestId)}>
            // <td className='td-left-h' style={{ paddingLeft: '15px' }}>{request.TaskType}</td>
            // <td className='td-left-h' style={{ paddingLeft: '15px' }}>{request.Subject}</td>
            // <td>{request.DueDate}</td>
            // <td>{request.Status}</td>
            // <td>{request.RequestBy}</td>
            // <td>...</td>
            // </tr>            
        ))
    ) : (
        <div className='td-center-h'>No data available</div>
    );

    const listHeadingRef = useRef(null);
    const prevListLength = usePrevious(requests.length);

    useEffect(() => {
        if (requests.length - prevListLength === -1) {
            listHeadingRef.current.focus();
        }
    }, [requests.length, prevListLength]);

    if (error) {
        return <div>Error: {error.message}</div>;
    }
    
    return (
        <div style={{ height: '400px', overflowY: 'scroll', paddingTop: '0px' }}>
            {/* <h2 id="list-heading" tabIndex="-1" ref={listHeadingRef}></h2> */}
            <Table bordered hover>
                <thead>
                <tr style={{ position: 'sticky', top: -1, height: '40px', zIndex: 1, backgroundColor: 'lightgray' }}>
                    <th scope="col">REQUEST TYPE</th>
                    <th scope="col">SUBJECT</th>
                    <th scope="col">DUE DATE</th>
                    <th scope="col">STATUS</th>
                    <th scope="col">SUBMITTED BY</th>
                    <th scope="col">LAST NOTE</th>
                    </tr>
                </thead>
                <tbody>
                    {requestList}
                </tbody>
            </Table>
        </div>
    );
}


export const RequestItem = (props) => {

    const navigate = useNavigate();

    const handleClickItem = (id) => {
        console.log('handleClickItem', id);
        navigate('/service-request/'+id);
    }

    let statusColor = '#bababa';

    switch(props.RequestStatus) {
        case "New Request":
            statusColor = "#ffc107";
            break;
        case "Assigned":
            statusColor = "#7da7d1";
            break;
        case "In Progress":
            statusColor = "#b5eba2";
            break;
        case "Complete":
            statusColor = "#3a9c6c";
            break;
    }

    const viewTemplate = (

        <tr className='cursor-pointer' key={props.id} onClick={() => handleClickItem(props.id)}>
        <td className='td-left-h' style={{ paddingLeft: '15px' }}>{props.TaskType}</td>
        <td className='td-left-h' style={{ paddingLeft: '15px' }}>{props.Subject}</td>
        <td>{props.RequestDate}</td>
        <td>
            <span className="list-label status-assigned" style={{ backgroundColor: statusColor }}>
                {props.RequestStatus}
            </span>                
        </td>
        <td>{props.RequestBy}</td>
        <td>...</td>
        </tr>

    );

    return <>{viewTemplate}</>;
}