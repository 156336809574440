// Report Forwarding Service (ScopeId 602)

import React, { useState, useRef, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { nanoid } from "nanoid";
import ListGroup from "react-bootstrap/ListGroup";

import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { protectedResources } from "../authConfig";

import Dropdown from 'react-bootstrap/Dropdown';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import { Button } from "react-bootstrap";


export const ModuleView602 = (props) => {

    const { instance } = useMsal();
    const account = instance.getActiveAccount();

    const { error, execute } = useFetchWithMsal({
        scopes: protectedResources.apiModuleList.scopes.write
    });

    const [allFiles, setAllFiles] = useState(props.todoListData);
    const [files, setFiles] = useState([]);
    const [fileVersions, setFileVersions] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const [fileCount, setFileCount] = useState(null);
    const [versionCount, setVersionCount] = useState(null);
    const [selectedFileName, setSelectedFileName] = useState(null);

    const [firmId, setFirmId] = useState();
    const [firmName, setFirmName] = useState();
    const [sendTo, setSendTo] = useState('');
    const [sendButton, setSendButton] = useState('secondary');
    const [sendDisable, setSendDisable] = useState(true);
    const [sentCheck, setSentCheck] = useState(false);

    const listHeadingRef = useRef(null);

    if (error) {
        return <div>Error: {error.message}</div>;
    }
    
    const handleSelectClient = (e) => {
        setFirmId(e.split('||')[0]);
        setFirmName(e.split('||')[1]);

        // console.log("allFiles", allFiles);

        const uniqueFileNames = [...new Set(allFiles.map((item) => item.attributes.name))];
        uniqueFileNames.sort();
        setFileCount(uniqueFileNames.length);
        setFiles(uniqueFileNames);
    }

    const handleSelectRecipient = (e) => {
        setSendTo(e);
        if (sendTo !== null) {
            setSendButton('success');
            setSendDisable(false);
            setSentCheck(false);
        }
    }


    const handleSelectFile = (event) => {
        const target = event.target;
        const selectedFile = target.value;
        // console.log("selectedFile", selectedFile);

        // Get file versions
        const filteredList = allFiles.filter((item) => 
          item.attributes.name === selectedFile);
        //   console.log("filteredList", filteredList);
  
        const dateList = [];
  
        for (const file of filteredList) {
            var displayDate = file.attributes.modified_at.replace('Z', ' ').replace('T', ' ') + " (File ID: " + file.id + ")";
            dateList.push( {displayDate: displayDate,  fileId: file.id, fileDate: new Date(file.attributes.modified_at)} );
        }

        // Sort the dates
        dateList.sort((a, b) => a.fileDate - b.fileDate);
        // console.log("dateList", dateList);

        // Last file
        const lastFile = allFiles.find((obj) => obj.id === dateList[dateList.length-1].fileId);
        console.log("lastFile", lastFile);

        setSelectedFileName(selectedFile);
        setVersionCount(dateList.length);
        setFileVersions(dateList);
        setSelectedOption(dateList[dateList.length-1].fileId);
        setSendButton('secondary');
        setSendDisable(true);
        setSentCheck(false);

    }


    const handleSendFile = () => {
        let fileId = fileVersions[fileVersions.length-1].fileId;
        console.log("Send FileId:", fileId);

        const sendParams = {
            id: fileId,
            fileName: selectedFileName,
            sendTo: sendTo
        };

        execute("POST", protectedResources.apiSendFile.endpoint, sendParams)
            .then((response) => {
                console.log(response);
                setSentCheck(true);
                setSendButton('secondary');
                setSendDisable(true);
            }
        );
    
        if (error) {
            return <div>Error: {error.message}</div>;
        }
    
        return <>done</>;
    };


    const SentConfirmation = () => (
        <span> &#10003; Sent </span>     
    )


    return (
        <div className="data-area-div" style={{padding: '40px'}}>

            <span id="fileCounter"></span>

            <Table bordered size="sm">
                <thead>
                    <tr>
                        <th colSpan={2} style={{paddingTop: '10px', paddingBottom: '10px', fontSize: 'large'}}>
                            Addepar Report Forwarding Service
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{width: '200px'}}>Select Client</td>
                        <td>
                            <Dropdown onSelect={handleSelectClient}>
                                <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={{ width: '100px' }}>
                                    {firmName}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item eventKey="575||Beemok">Beemok</Dropdown.Item>
                                    {/* <Dropdown.Item eventKey="123||Test">Test</Dropdown.Item> */}
                                </Dropdown.Menu>
                            </Dropdown>
                        </td>
                    </tr>

                    <tr>
                        <td style={{width: '200px'}}>Report List<br />
                        {fileCount}
                        </td>
                        <td>
                            <Form.Select aria-label="Default select" htmlSize="10" onChange={handleSelectFile}>
                                {files.map((fileName, index) => {
                                    return (
                                        <option key={index} value={fileName}>
                                        {fileName}
                                        </option>
                                    );
                                })}
                            </Form.Select>                       
                        </td>
                    </tr>

                    <tr>
                    <td style={{width: '200px'}}>Report Versions<br />
                    {versionCount}
                    </td>
                        <td>
                            <Form.Select aria-label="Default select" value={selectedOption} htmlSize="5" disabled>
                                {fileVersions.map((file) => {
                                    return (
                                        <option key={file.fileId} value={file.fileId}>
                                        {file.displayDate}
                                        </option>
                                    );
                                })}
                            </Form.Select>                       
                        </td>
                    </tr>

                    <tr>
                        <td style={{ width: '200px' }}>Send To</td>
                        <td>
                            <Table borderless>
                                <tbody>
                                    <tr >   
                                        <td style={{width: '300px'}}>
                                            <Dropdown onSelect={handleSelectRecipient} style={{ marginBottom: '-15px' }}>
                                                <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={{ width: '240px' }}>
                                                    {sendTo}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item eventKey="hektor@amplifyadvisory.com">hektor@amplifyadvisory.com</Dropdown.Item>
                                                    <Dropdown.Item eventKey="josh@amplifyadvisory.com">josh@amplifyadvisory.com</Dropdown.Item>
                                                    <Dropdown.Item eventKey="samim@amplifyadvisory.com">samim@amplifyadvisory.com</Dropdown.Item>
                                                    <Dropdown.Item eventKey="valentin@amplifyadvisory.com">valentin@amplifyadvisory.com</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>                                            
                                        </td>
                                        <td style={{ textAlign: 'end',}}>
                                            <Button variant={sendButton} disabled={sendDisable} onClick={handleSendFile}
                                                style={{ width: '80px', marginBottom: '-10px' }}>Send</Button>                  
                                        </td>
                                        <td style={{ width: '100px', textAlign: 'start', fontSize: 'large', fontWeight: 'bold', color: '#198754', 
                                                     paddingTop: '13px' }}>
                                            { sentCheck ? <SentConfirmation /> : null }
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </td>
                    </tr>

                </tbody>
            </Table>
           
        </div>
    );
}
