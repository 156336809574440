import { useEffect, useState } from 'react';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';

import { TaskListView } from '../components/TaskListView';
import { loginRequest, protectedResources } from "../authConfig";
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import {Routes, Route, useNavigate} from 'react-router-dom';

import { Container, Button, ButtonGroup, Form, FormGroup, Label } from 'reactstrap';
import { Input, Table, Row, Col } from 'reactstrap';
import Select from 'react-select';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import { CircularProgress } from '@mui/material';

const TaskListContent = () => {
    
    const { error, execute } = useFetchWithMsal({
        scopes: protectedResources.apiTaskList.scopes.read,
    });

    const navigate = useNavigate();
    const [taskData, setTaskData] = useState(null);
    const [sendButton, setSendButton] = useState('secondary');
    
    const [searchWord, setSearchWord] = useState('');
    const [selectedPageSize, setSelectedPageSize] = useState(null);
    const [pageSizeList, setPageSizeList] = useState(null);

    const [dateRangeOption, setDateRangeOption] = useState(null);
    const [isCalendarView, setIsCalendarView] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!taskData) {

            (async () => {
                await getFilteredTaskList();
            })();

        }
    }, [execute, taskData])


    useEffect(() => {
        console.log('isLoading', isLoading);
    }, [isLoading])


    if (error) {
        return <div>Error: {error.message}</div>;
    }

    const handleListViewChange = () => {
        console.log('handleListViewChange');
    };

    const handleDateToggle = () => {
        console.log('handleDateToggle');
    };

    const handleNewTask = () => {
        console.log('handleNewTask');
        navigate('/task-new/12');  //--****--
    };

    const handleSearchWordEntry = (e) => {
        setSearchWord(e.target.value);
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            console.log('handleKeyDown', event.target.value);
            (async () => {
                await getFilteredTaskList();
            })();
        }
    }
    const handleSearchSubmit = (e) => {
        setSearchWord(e.target.value);
    }


    const getFilteredTaskList = async () => {
        console.log('getFilteredTaskList');

        setTaskData(null);

        const sendParams = {
            SearchWord: searchWord,
            FilterMyItems: null,
            FilterHideCompleted: true,
            FilterClient: 0,
            FilterTeam: 0,
            FilterPriority: 0,
            FilterStatus: -1,
            FilterDateFrom: '2004-04-12 06:22:42',
            FilterDateTo: '2025-04-07 06:22:42',
            FilterCreatedBy: 0,
            FilterAssignedTo: 0,
            FilterApprovedBy: 0,
            PageSize: 25,
            PageNumber: 1,
            SortColumn: 'default_calendar',
            SortAscending: true,
        };

        console.log('sendParams', sendParams);

        // execute("GET", protectedResources.apiTaskList.endpoint, sendParams)
        execute("POST", protectedResources.apiTaskList.endpoint, sendParams)
            .then((response) => {
                setTaskData(response);
                setIsLoading(false);
                console.log(response);
            }
        );        
    }


    return <>
        <div className="svc-req-page">

        <Table className="svc-req-list-head">
            <thead>
                <tr>
                    <th style={{fontSize: '1.3em', verticalAlign: 'top', paddingTop: '12px', paddingLeft: '15px'}}>
                        Tasks {taskData ? '('+taskData.TaskList.length+')' : ''}
                    </th>
                    <th>
                        <div style={{ display: 'flex', alignItems: 'center', float: 'right' }}>
                            <Button variant={sendButton} onClick={handleNewTask}
                                    style={{ float: 'right' }}>New Task</Button>                  
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody className="svc-req-list-body">

                <tr>
                    <td>
                        <Row>
                            <Col>
                                {/* ============================================================================================================ */}
                                <Input
                                    name="SearchWord"
                                    type="search"
                                    placeholder="Search"
                                    autoFocus
                                    className="me-2"
                                    aria-label="Search"
                                    style={{ width: '180px', float: 'left'  }}
                                    value={searchWord || ''}
                                    onChange={(e) => { handleSearchWordEntry(e); }}                  
                                    onKeyDown={handleKeyDown}
                                />       
                                <Button className="fz-primary-light" style={{ float: 'left' }}>Search</Button>
                                <Button className="fz-primary-light" style={{ float: 'left', marginLeft: '20px', marginRight: '20px' }}>Filters</Button>

                                <span onClick={() => handleListViewChange}>
                                    {/* {isCalendarView ? <CalendarMonthIcon /> : <ViewHeadlineIcon /> } */}
                                    <CalendarMonthIcon style={{ width: '30px', height: '30px', marginRight: '10px' }} />
                                </span>
                                <span onClick={() => handleListViewChange}>
                                    <ViewHeadlineIcon style={{ width: '30px', height: '30px', marginRight: '10px' }} />
                                </span>

                                <ButtonGroup onClick={handleDateToggle}>
                                <Button type='button' value='1' 
                                        className={dateRangeOption === 1 ? 'toggle-button date-range-on' : 'toggle-button date-range-off'}
                                        >1 wk</Button>
                                <Button type='button' value='2' 
                                        className={dateRangeOption === 2 ? 'toggle-button date-range-on' : 'toggle-button date-range-off'}
                                        >2 wk</Button>
                                <Button type='button' value='4' 
                                        className={dateRangeOption === 4 ? 'toggle-button date-range-on' : 'toggle-button date-range-off'}
                                        >1 mo</Button>
                                <Button type='button' value='5' 
                                        className={dateRangeOption === 5 ? 'toggle-button date-range-on' : 'toggle-button date-range-off'}
                                        >All</Button>
                                </ButtonGroup>&nbsp;   

                                {/* ============================================================================================================ */}
                            </Col>
                        </Row>
                    </td>
                    <td style={{ float: 'right' }}>

                        {/* ============================================================================================================ */}
                        <Select className='pg-select'
                            value={selectedPageSize}
                            placeholder="Page 1 of 25"
                            // onChange={this.handleChange4}
                            // options={ pageSizeList.map( opt => ({ 
                            //     label: opt.PageSize, 
                            //     value: opt.PageSize 
                            // })) }
                        />                             

                        <span onClick={() => handleListViewChange}>
                            <ArrowCircleUpIcon style={{ width: '30px', height: '30px', 
                                marginTop: '5px', marginRight: '10px', float: 'left' }} />
                        </span>

                        <span onClick={() => handleListViewChange}>
                            <ArrowCircleDownIcon style={{ width: '30px', height: '30px', 
                                marginTop: '5px', marginRight: '15px', float: 'left' }} />
                        </span>

                        <Select className='pg-size-select'
                            value={selectedPageSize}
                            placeholder="10"
                            // onChange={this.handleChange4}
                            // options={ pageSizeList.map( opt => ({ 
                            //     label: opt.PageSize, 
                            //     value: opt.PageSize 
                            // })) }
                        />                             
                        {/* ============================================================================================================ */}

                    </td>
                </tr>

                <tr>
                    <td colSpan={2}>
                        <section className="svc-req-scroll custom-ht-1">
                        { !isLoading && taskData ? <TaskListView taskData={taskData} /> 
                        : <CircularProgress style={{ width: '25px', height: '25px', verticalAlign: 'middle', color: '#198754' }}/> }
                        </section>
                    </td>
                </tr>

            </tbody>

        </Table>

        </div>

    </>;
};

/**
 * The `MsalAuthenticationTemplate` component will render its children if a user is authenticated
 * or attempt to sign a user in. Just provide it with the interaction type you would like to use
 * (redirect or popup) and optionally a request object to be passed to the login API, a component to display while
 * authentication is in progress or a component to display if an error occurs. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const TaskList = () => {
    const authRequest = {
        ...loginRequest,
    };

    return (
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest}
        >
            <TaskListContent />
        </MsalAuthenticationTemplate>
    );
};
