import React, { useState, useRef, useEffect } from "react";
import {Routes, Route, useNavigate} from 'react-router-dom';
import { useMsal } from "@azure/msal-react";
import { nanoid } from "nanoid";

import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { protectedResources } from "../authConfig";

import ListGroup from "react-bootstrap/ListGroup";

function usePrevious(value) {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
}

export const TeamListView = (props) => {
    const { instance } = useMsal();
    const account = instance.getActiveAccount();

    const { error, execute } = useFetchWithMsal({
        scopes: protectedResources.apiTeamList.scopes.write
    });

    const [teams, setTeams] = useState(props.teamListData);

    const teamList = teams.map(team => (
        <TeamItem
            id={team.TeamId}
            TeamName={team.TeamName}
            key={team.TeamId}
        />
    ));

    const listHeadingRef = useRef(null);
    const prevTeamLength = usePrevious(teams.length);

    useEffect(() => {
        if (teams.length - prevTeamLength === -1) {
            listHeadingRef.current.focus();
        }
    }, [teams.length, prevTeamLength]);

    if (error) {
        return <div>Error: {error.message}</div>;
    }
    
    return (
        <div className="data-area-div">
            <h2 id="list-heading" tabIndex="-1" ref={listHeadingRef}></h2>
            <ListGroup style={{ display: 'flex', flex: 1, width: '100%' }}>
                {teamList}
            </ListGroup>
        </div>
    );
}


export const TeamItem = (props) => {

    const navigate = useNavigate();

    const handleClickItem = (e) => {
        // navigate('/module602');
        console.log('TeamItem.handleClickItem');
    }

    const viewTemplate = (
        <div>
            <label action onClick={() => handleClickItem()}>{props.TeamName}</label>
        </div>
    );

    return <ListGroup.Item className="todo-item">{viewTemplate}</ListGroup.Item>;
}
