import React, { useState, useRef, useEffect } from "react";
import {Routes, Route, useNavigate} from 'react-router-dom';
import { useMsal } from "@azure/msal-react";
import { nanoid } from "nanoid";

import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { protectedResources } from "../authConfig";

import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Form from "react-bootstrap/Form";
import { Ellipsis } from "react-bootstrap/esm/PageItem";

function usePrevious(value) {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
}

export const ClientProfileView = (props) => {

    const { error, execute } = useFetchWithMsal({
        scopes: protectedResources.apiClientProfile.scopes.read,
    });

    const [clientProfile, setClientProfile] = useState();

    useEffect(() => {
        if (!clientProfile) {
            execute("GET", protectedResources.apiClientProfile.endpoint).then((response) => {
                if (response) {
                    setClientProfile(response[0].SettingValue);
                    console.log(response[0].SettingValue);    
                }
            });

            let fcmToken = localStorage.getItem("FCMToken");

            if (fcmToken) {
            
                const sendParams = {
                    FCMToken: fcmToken,
                };
                console.log("sendParams - FCMToken", sendParams);

                // Update server with latest token
                execute("POST", protectedResources.apiUpdateFCMToken.endpoint, sendParams)
                    .then((response) => {
                        console.log('apiUpdateFCMToken', response);
                    }
                );  
            }
        }
    }, [execute, clientProfile])

    if (error) {
        return <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', display: 'inline-block', maxWidth: '600px' }} >Error: {error.message}</div>;
    }

    return <>{clientProfile ? clientProfile : null}</>;

}

export const ClientLogo = (props) => {

    const { error, execute } = useFetchWithMsal({
        scopes: protectedResources.apiClientProfile.scopes.read,
    });

    const [clientLogo, setClientLogo] = useState();

    useEffect(() => {
        if (!clientLogo) {
            execute("GET", protectedResources.apiClientProfile.endpoint).then((response) => {
                setClientLogo(response[1].SettingValue);
                console.log(response[1].SettingValue);
            });
        }
    }, [execute, clientLogo])

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return <>{clientLogo ? 
            <img src={clientLogo} style={{ backgroundColor: '#294B55', padding: '20px', height: '150px' }}></img> 
        : null}
    </>;

}
