import { useEffect, useState, useContext } from 'react';
import { DataContext } from '../../App';

import { Nav, Navbar, Dropdown, DropdownButton, Button } from 'react-bootstrap';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { InteractionStatus } from "@azure/msal-browser"; 
import { loginRequest, b2cPolicies } from '../../authConfig';
import { ClientProfileView } from '../../components/ClientProfileView';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';

export const NavigationBar = (props) => {

    const { instance, inProgress } = useMsal();
    const [messageCount, setMessageCount] = useState();
    const { data, setData } = useContext(DataContext);

    let activeAccount;

    // if (props.instance) {
    //    activeAccount = props.instance.getActiveAccount();
    //    console.log("activeAccount", activeAccount);
    // }


     if (instance) {
         activeAccount = instance.getActiveAccount();
         console.log("activeAccount", activeAccount);
     }


    useEffect(() => {
        setMessageCount(5);
    });


    const handleLoginRedirect = () => {
        instance.loginRedirect(loginRequest).catch((error) => console.log(error));
    };

    const handleLogoutRedirect = () => {
        instance.logoutRedirect();
    };

    
    return (
        <>
            {/* <Navbar variant="dark" className="navbarStyle"> */}
            <Navbar variant="dark" className="title-bar">
                <AuthenticatedTemplate>
                    <a className="navbar-brand" href="/">
                        <ClientProfileView />
                    </a>

                    {/* <div>
                        {props.notificationAllowed}
                    </div> */}
                    {/* {data} */}

                    <div style={{ width: '100%', paddingRight: '25px' }}>
                        <NotificationsOffIcon style={{ width: '25px', height: '25px', float: 'right' }} />
                        <div className="message-count" style={{ float: 'right', paddingRight: '20px' }} >
                            <Nav.Link className="" href="/modulelist">
                                Messages
                                <span className="badge">{messageCount}</span>
                            </Nav.Link >
                        </div>     
                    </div>

                    {/* <Nav.Link className="navbarButton" href="/todolist">
                        Todolist
                    </Nav.Link> */}
                    {/* <Nav.Link className="navbarButton" href="/modulelist">
                        Ops Command Center
                    </Nav.Link>
                    <Nav.Link className="navbarButton" href="/message-list">
                        Messages
                    </Nav.Link> */}

                    <div className="collapse navbar-collapse justify-content-end">
                        <DropdownButton
                            variant="success"
                            drop="start"
                            title={activeAccount && activeAccount.username ? ' '+ activeAccount.username : 'Unknown'}>
                            <Dropdown.Item as="button" onClick={handleLogoutRedirect}>
                                Sign out
                            </Dropdown.Item>
                        </DropdownButton>
                    </div>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <a className="navbar-brand" href="/">
                        Finzen Portal
                    </a>
                    <div className="collapse navbar-collapse justify-content-end">
                        <Button variant="success" type="button" onClick={handleLoginRedirect}>Sign in</Button>
                    </div>
                </UnauthenticatedTemplate>
            </Navbar>
        </>
    );
};
