import React, { useState, useRef, useEffect } from "react";
import {Routes, Route, useNavigate} from 'react-router-dom';
import { useMsal } from "@azure/msal-react";
import { nanoid } from "nanoid";

import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { protectedResources } from "../authConfig";

import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";

function usePrevious(value) {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
}

export const ClientListView = (props) => {
    const { instance } = useMsal();
    const account = instance.getActiveAccount();

    const { error, execute } = useFetchWithMsal({
        scopes: protectedResources.apiClientList.scopes.write
    });

    const [tasks, setTasks] = useState(props.clientListData);


    let activeAccount;
    let fs_list = [];

    if (instance) {
        activeAccount = instance.getActiveAccount();
        let ext_fs = activeAccount.idTokenClaims.extension_fs;

        if (ext_fs !== null)
            fs_list = ext_fs.split(' ');

        console.log("fs_list", fs_list);
        // console.log("activeAccount", activeAccount);
        console.log("extension_fs", activeAccount.idTokenClaims.extension_fs);
        console.log( fs_list.includes('502') );
    }


    // Empty list check
    // const taskList = tasks.length > 0 ? (
    // ) : (
    //     <div class='td-center-h'>No data available</div>
    // );

    const taskList = tasks.length > 0 ? (
        tasks.map(task => (
            <ClientItem
                id={task.ClientId}
                ClientName={task.ClientName}
                key={task.ClientId}
            />
        ))
    ) : (
        <div className='td-center-h'>No data available</div>
    );

    const listHeadingRef = useRef(null);
    const prevTaskLength = usePrevious(tasks.length);

    useEffect(() => {
        if (tasks.length - prevTaskLength === -1) {
            listHeadingRef.current.focus();
        }
    }, [tasks.length, prevTaskLength]);

    if (error) {
        return <div>Error: {error.message}</div>;
    }
    
    return (
        <div className="data-area-div">
            <h2 id="list-heading" tabIndex="-1" ref={listHeadingRef}></h2>
            <ListGroup className="todo-list">
                {taskList}
            </ListGroup>
        </div>
    );
}


export const ClientItem = (props) => {

    const navigate = useNavigate();

    const handleClickItem = (e) => {
        console.log('handleClickItem');
        // this.props.navigation('/todolist/'+categoryTypeId);
        // navigate('/module602');
        console.log('ClientItem.handleClickItem');
    }

    const viewTemplate = (
        <div className="todo-view">
            <Form.Group>
                <label className="list-label" htmlFor={props.ClientId}>
                    {props.ClientName}
                </label>
                <ButtonGroup className="todo-view-btn">
                    <Button variant="danger" onClick={() => handleClickItem()}>Open</Button>
                </ButtonGroup>
            </Form.Group>
        </div>
    );

    return <ListGroup.Item className="todo-item">{viewTemplate}</ListGroup.Item>;
}