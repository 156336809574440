import { useEffect, useState } from 'react';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';

import { WorkflowListView } from '../components/WorkflowListView';
import { loginRequest, protectedResources } from "../authConfig";
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import {Routes, Route, useNavigate} from 'react-router-dom';

import { Container, Button, ButtonGroup, Form, FormGroup, Label } from 'reactstrap';
import { Input, Table, Row, Col } from 'reactstrap';
import Select from 'react-select';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import { CircularProgress } from '@mui/material';

const WorkflowListContent = () => {

    const { error, execute } = useFetchWithMsal({
        scopes: protectedResources.apiWorkflowList.scopes.read,
    });

    const navigate = useNavigate();
    const [workflowData, setWorkflowData] = useState(null);
    const [sendButton, setSendButton] = useState('secondary');

    const [searchWord, setSearchWord] = useState(null);
    const [selectedPageSize, setSelectedPageSize] = useState(null);
    const [pageSizeList, setPageSizeList] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!workflowData) {
            execute("GET", protectedResources.apiWorkflowList.endpoint).then((response) => {
                setWorkflowData(response);
                setIsLoading(false);
                console.log(response);
            });
        }
    }, [execute, workflowData])


    useEffect(() => {
        console.log('isLoading', isLoading);
    }, [isLoading])


    if (error) {
        return <div>Error: {error.message}</div>;
    }

    const handleListViewChange = () => {
        console.log('handleListViewChange');
    };

    const handleNewWorkflow = () => {
        console.log('handleNewWorkflow');
        navigate('/service-request');
    };

    const handleSearchWordEntry = (e) => {
        setSearchWord(e.target.value);
    }

    return <>
        <div className="svc-req-page">

        <Table className="svc-req-list-head">
            <thead>
                <tr>
                    <th style={{fontSize: '1.3em', verticalAlign: 'top', paddingTop: '12px', paddingLeft: '15px'}}>
                        Workflows {workflowData ? '('+workflowData.length+')' : ''}
                    </th>
                    <th>
                        <div style={{ display: 'flex', alignItems: 'center', float: 'right' }}>
                            <Button variant={sendButton} onClick={handleNewWorkflow}
                                    style={{ float: 'right' }}>New Workflow</Button>                  
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody className="svc-req-list-body">

                <tr>
                    <td>
                        <Row>
                            <Col>
                                {/* ============================================================================================================ */}
                                <Input
                                    name="SearchWord"
                                    type="search"
                                    placeholder="Search"
                                    autoFocus
                                    className="me-2"
                                    aria-label="Search"
                                    style={{ width: '180px', float: 'left'  }}
                                    value={searchWord || ''}
                                    onChange={(e) => { handleSearchWordEntry(e); }}                  
                                    // onKeyDown={(event) => {
                                    //     if (event.key === 'Enter') {
                                    //     handleSearchSubmit(event);
                                    //     }
                                    // }}
                                />       
                                <Button className="fz-primary-light" style={{ float: 'left' }}>Search</Button>
                                <Button className="fz-primary-light" style={{ float: 'left', marginLeft: '20px', marginRight: '20px' }}>Filters</Button>

                                {/* ============================================================================================================ */}
                            </Col>
                        </Row>
                    </td>
                    <td style={{ float: 'right' }}>

                        {/* ============================================================================================================ */}
                        <Select className='pg-select'
                            value={selectedPageSize}
                            placeholder="Page 1 of 25"
                            // onChange={this.handleChange4}
                            // options={ pageSizeList.map( opt => ({ 
                            //     label: opt.PageSize, 
                            //     value: opt.PageSize 
                            // })) }
                        />                             

                        <span onClick={() => handleListViewChange}>
                            <ArrowCircleUpIcon style={{ width: '30px', height: '30px', 
                                marginTop: '5px', marginRight: '10px', float: 'left' }} />
                        </span>

                        <span onClick={() => handleListViewChange}>
                            <ArrowCircleDownIcon style={{ width: '30px', height: '30px', 
                                marginTop: '5px', marginRight: '15px', float: 'left' }} />
                        </span>

                        <Select className='pg-size-select'
                            value={selectedPageSize}
                            placeholder="10"
                            // onChange={this.handleChange4}
                            // options={ pageSizeList.map( opt => ({ 
                            //     label: opt.PageSize, 
                            //     value: opt.PageSize 
                            // })) }
                        />                             
                        {/* ============================================================================================================ */}

                    </td>
                </tr>

                <tr>
                    <td colSpan={2}>
                        <section className="svc-req-scroll custom-ht-1">
                        { !isLoading && workflowData ? <WorkflowListView workflowData={workflowData} /> 
                        : <CircularProgress style={{ width: '25px', height: '25px', verticalAlign: 'middle', color: '#198754' }}/> }
                        </section>
                    </td>
                </tr>

            </tbody>

        </Table>

        </div>

    </>;
};

/**
 * The `MsalAuthenticationTemplate` component will render its children if a user is authenticated
 * or attempt to sign a user in. Just provide it with the interaction type you would like to use
 * (redirect or popup) and optionally a request object to be passed to the login API, a component to display while
 * authentication is in progress or a component to display if an error occurs. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const WorkflowList = () => {
    const authRequest = {
        ...loginRequest,
    };

    return (
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest}
        >
            <WorkflowListContent />
        </MsalAuthenticationTemplate>
    );
};
