import { useEffect, useState } from 'react';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';

import { loginRequest, protectedResources } from "../authConfig";
import useFetchWithMsal from '../hooks/useFetchWithMsal';

import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import "react-pdf/dist/esm/Page/TextLayer.css";

import { TreeView } from "../components/treeview/TreeView";

if (process.env.NODE_ENV === "development") {
  pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
  ).toString();
} else {
  pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    process.env.REACT_APP_API_URL+'/pdf.worker.min.js',
    import.meta.url,
  ).toString()
}


const KnowledgeTreeContent = (props) => {
    
  const { error, execute } = useFetchWithMsal({
      scopes: protectedResources.apiKnowledgeTree.scopes.read,
  });
    
  const [isLoaded, setIsLoaded] = useState(false);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [filePath, setFilePath] = useState();
  const [fileId, setFileId] = useState();
  const [fileContent, setFileContent] = useState();


  const updateFileId = (newFileId) => {
    // console.log("updateFileId", newFileId);
    setFileId(newFileId);
    getKBFileContent(newFileId);
  };



  const goToPrevPage = () =>
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

  const goToNextPage = () =>
    setPageNumber(
      pageNumber + 1 >= numPages ? numPages : pageNumber + 1,
    );
    
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
    console.log("onDocumentLoadSuccess");
  };

  const getKBFileContent = (selectedFileId) => {

    execute("GET", protectedResources.apiKnowledgeBaseFile.endpoint + `/${selectedFileId}`).then((response) => {
      setFileContent(response.data);
        console.log(response.data);
    });

  }

  return (

    <table>
    <tbody>
    <tr>
      <td className="td-top-v td-50pct fz-gray-bg-6 page-padding">

        <h4 style={{ paddingTop: '8px', paddingBottom: '8px', paddingLeft: '14px', color: '#434343' }}>Knowledge Tree</h4>
        <h5> - Under Construction -</h5>

        {/* <TreeView updateFilePath={updateFilePath} updateFileId={updateFileId} /> */}
        {/* <TreeView treeData2={treeData2} /> */}

        {/* <TreeView updateFileId={updateFileId} /> */}

      </td>
      {/* <td className="td-top-v td-50pct fz-gray-bg-8 page-padding">

        <div className="kbDiv1">
          <nav className="fz-gray-bg-7">
            <img src={process.env.PUBLIC_URL +"/arrow_circle_up.png"} style={{ marginLeft: '10px', marginBottom: '5px' }}
                 title="Back" className='cursor-pointer' onClick={ goToPrevPage } />
            <img src={process.env.PUBLIC_URL +"/arrow_circle_down.png"} style={{ marginRight: '10px', marginBottom: '5px' }}
                 title="Back" className='cursor-pointer' onClick={ goToNextPage } />
            <span>
              Page {pageNumber} of {numPages}
            </span>
          </nav>

          <div className="kbDiv2" style={{ padding: '5px', overflow: 'scroll' }}>
            <Document file={filePath} onLoadSuccess={onDocumentLoadSuccess} /> 
            <Document file={`data:application/pdf;base64,${fileContent}`} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={pageNumber} canvasBackground="#fafaf2" />
            </Document>
          </div>

        </div>

      </td> */}
    </tr>
    </tbody>
  </table>

  );

};


/**
 * The `MsalAuthenticationTemplate` component will render its children if a user is authenticated
 * or attempt to sign a user in. Just provide it with the interaction type you would like to use
 * (redirect or popup) and optionally a request object to be passed to the login API, a component to display while
 * authentication is in progress or a component to display if an error occurs. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const KnowledgeTree = () => {
  const authRequest = {
      ...loginRequest,
  };

  return (
      <MsalAuthenticationTemplate 
          interactionType={InteractionType.Redirect} 
          authenticationRequest={authRequest}>
          <KnowledgeTreeContent />
      </MsalAuthenticationTemplate>
  );
};
