import React, { useState, useRef, useEffect } from "react";

export const PushNotification = (props) => {

    const [msgLine1, setMsgLine1] = useState(props.msgLine1);
    const [msgLine2, setMsgLine2] = useState(props.msgLine2);
    const [msgLine3, setMsgLine3] = useState(props.msgLine3);
    const [msgData, setMsgData] = useState(props.msgData);

    const handleButtonClick = () => {
      console.log("handleAlertClick");
      // window.open("http://localhost:3002/service-requests"+workflowProcessId,"_self")
      // window.open("/service-request/"+msgData.RequestId,"_self")
      if (msgData.hasOwnProperty('url'))
        window.open(msgData.url,"_self")
    }
  
    return (
      <div className="notification-toast" onClick={handleButtonClick}>
        <div className="toast-line1">{msgLine1}</div>
        <div className="toast-line2">{msgLine2}</div>
        {/* <div className="toast-line3">{msgLine3}</div> */}
      </div>
    );

}
