import { useEffect, useState } from 'react';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';

import { RecentRequestsView } from '../components/RecentRequestsView';
import { loginRequest, protectedResources } from "../authConfig";
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import {Routes, Route, useNavigate} from 'react-router-dom';

import { Container, Button, ButtonGroup, Form, FormGroup, Label } from 'reactstrap';
import { Input, Table, Row, Col } from 'reactstrap';
import Select from 'react-select';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import { CircularProgress } from '@mui/material';

const RecentRequestsContent = () => {

    const { error, execute } = useFetchWithMsal({
        scopes: protectedResources.apiRecentRequests.scopes.read,
    });

    const navigate = useNavigate();
    const [requestData, setRequestData] = useState(null);
    const [sendButton, setSendButton] = useState('secondary');

    const [searchWord, setSearchWord] = useState(null);
    const [selectedPageSize, setSelectedPageSize] = useState(null);
    const [pageSizeList, setPageSizeList] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
        if (!requestData) {
            execute("GET", protectedResources.apiRecentRequests.endpoint).then((response) => {
                setRequestData(response);
                setIsLoading(false);
                console.log(response);
            });
        }
    }, [execute, requestData])


    useEffect(() => {
        console.log('isLoading', isLoading);
    }, [isLoading])


    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return <>
      { !isLoading && requestData ? <RecentRequestsView requestData={requestData} /> 
      : <CircularProgress style={{ width: '35px', height: '35px', verticalAlign: 'middle', color: '#198754' }}/> }
    </>;
};

/**
 * The `MsalAuthenticationTemplate` component will render its children if a user is authenticated
 * or attempt to sign a user in. Just provide it with the interaction type you would like to use
 * (redirect or popup) and optionally a request object to be passed to the login API, a component to display while
 * authentication is in progress or a component to display if an error occurs. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const RecentRequests = () => {
    const authRequest = {
        ...loginRequest,
    };

    return (
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest}
        >
            <RecentRequestsContent />
        </MsalAuthenticationTemplate>
    );
};
