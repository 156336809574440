import React, { useState, useRef, useEffect } from "react";
import {Routes, Route, useNavigate, useParams} from 'react-router-dom';
import { useMsal } from "@azure/msal-react";
import { nanoid } from "nanoid";
import ListGroup from "react-bootstrap/ListGroup";

import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { protectedResources } from "../authConfig";

import Dropdown from 'react-bootstrap/Dropdown';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import { Button, InputGroup } from "react-bootstrap";

import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { CircularProgress } from '@mui/material';

import { FormGroup, Input, Label, ButtonGroup } from 'reactstrap';
import { Container, Row, Col } from 'reactstrap';

import DatePicker from "react-datepicker";
import date from 'date-and-time'; 
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';

export const TaskEditView = (props) => {

    const { instance, inProgress } = useMsal();
    let activeAccount;
    let fs_list = [];
  
    if (instance) {
        activeAccount = instance.getActiveAccount();
        let ext_fs = activeAccount.idTokenClaims.extension_fs;
  
        if (ext_fs !== null)
            fs_list = ext_fs.split(' ');
    }

    const { error, execute } = useFetchWithMsal({
        scopes: protectedResources.apiTask.scopes.write
    });

    const navigate = useNavigate();
    const { id } = useParams();

    const [formMode, setFormMode] = useState(props.formMode);
    if (formMode === undefined)
        setFormMode('edit')

    const [clientRequest, setClientRequest] = useState(null);
    const [messageList, setMessageList] = useState([]);
    const [editDisabled, setEditDisabled] = useState(true);
    const [agentEnabled, setAgentEnabled] = useState(false);
    const [statusLabel, setStatusLabel] = useState('');
    const [statusColor, setStatusColor] = useState('#bababa');

    const [requestType, setRequestType] = useState('');
    const [requestSubject, setRequestSubject] = useState('');
    const [requestInstructions, setRequestInstructions] = useState('');
    const [requestPriority, setRequestPriority] = useState('');
    const [requestAssignedTo, setRequestAssignedTo] = useState('');
    const [requestAssignedDate, setRequestAssignedDate] = useState(null);
    const [requestResolvedBy, setRequestResolvedBy] = useState('');
    const [requestResolvedDate, setRequestResolvedDate] = useState(null);
    const [selectedDueDate, setSelectedDueDate] = useState(null);
    const [requestStatus, setRequestStatus] = useState('');

    const [categoryList, setCategoryList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [typeList, setTypeList] = useState([]);
    const [selectedType, setSelectedType] = useState(null);
    const [priorityList, setPriorityList] = useState([]);
    const [selectedPriority, setSelectedPriority] = useState(null);
    const [statusList, setStatusList] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [requestedBy, setRequestedBy] = useState(null);
    const [clientName, setClientName] = useState(null);
    const [teamList, setTeamList] = useState([]);
    const [selectedAssignedTo, setSelectedAssignedTo] = useState(null);
    const [selectedResolvedBy, setSelectedResolvedBy] = useState(null);

    const [messageDisplay, setMessageDisplay] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const listHeadingRef = useRef(null);
    let messages = [];


    const [toggleTaskPriority, setToggleTaskPriority] = useState(1);
    const [kbFileId, setKbFileId] = useState(null);
    const [taskDetail, setTaskDetail] = useState(null);
    const [personDetail, setPersonDetail] = useState(null);
    const [selectedClient, setSelectedClient] = useState(null);
    const [clientList, setClientList] = useState([]);
    const [personList, setPersonList] = useState([]);
    const [durationList, setDurationList] = useState([]);
    const [selectedDuration, setSelectedDuration] = useState(null);
    const [selectedTeamValue, setSelectedTeamValue] = useState(null);
    const [taskDueDateState, setTaskDueDateState] = useState(null);
    const [teamName, setTeamName] = useState(null);
    const [createdByPerson, setCreatedByPerson] = useState(null);
    const [selectedAssignTo, setSelectedAssignTo] = useState(null);
    const [selectedCompleteBy, setSelectedCompleteBy] = useState(null);
    const [displayCreateDate, setDisplayCreateDate] = useState(null);
    const [displayAssignDate, setDisplayAssignDate] = useState(null);
    const [displayCompleteDate, setDisplayCompleteDate] = useState(null);
    

    useEffect(() => {
        setStatusList([
            { StatusName: "Not Started", StatusId: 0 },
            { StatusName: "Started", StatusId: 1 },
            { StatusName: "Processed", StatusId: 2 },
            { StatusName: "Complete", StatusId: 3 },
            { StatusName: "Rework - In Progress", StatusId: 4 },
            { StatusName: "Rework - Pending Review", StatusId: 5 }
        ]);

        setDurationList([
            { label: "1", value: 1 },
            { label: "2", value: 2 },
            { label: "3", value: 3 },
            { label: "4", value: 4 },
            { label: "5", value: 5 },
            { label: "6", value: 6 },
            { label: "7", value: 7 },
            { label: "8", value: 8 },
            { label: "9", value: 9 },
            { label: "10", value: 10 },
            { label: "11", value: 11 },
            { label: "12", value: 12 },
            { label: "13", value: 13 },
            { label: "14", value: 14 },
            { label: "15", value: 15 },
            { label: "16", value: 16 },
            { label: "17", value: 17 },
            { label: "18", value: 18 },
            { label: "19", value: 19 },
            { label: "18", value: 18 },
            { label: "20", value: 20 },
            { label: "21", value: 21 },
            { label: "22", value: 22 },
            { label: "23", value: 23 },
            { label: "24", value: 24 },
            { label: "25", value: 25 },
            { label: "26", value: 26 },
            { label: "27", value: 27 },
            { label: "28", value: 28 },
            { label: "29", value: 29 },
            { label: "30", value: 30 },
            { label: "31", value: 31 },
        ]);

        setPriorityList([
            { label: "Urgent", value: 1 },
            { label: "High", value: 2 },
            { label: "Regular", value: 3 },
            { label: "Low", value: 4 },
        ]);

        // setTeamList([
        //     { label: "Ghrewati, Samim", value: '57e11066-b889-47bd-8387-871956664457' },
        //     { label: "Kotran, Josh", value: '08215e18-9021-4c9b-9753-272822b4c46b' },
        // ]);

        if (formMode === "edit") {
            console.log("Load task data for workflow_process.WorkflowProcessId: " + id);

            execute("GET", protectedResources.apiTask.endpoint+id)
                .then((response) => {

                    console.log('taskDetail', response);

                    execute("GET", protectedResources.apiTaskFormLists.endpoint)
                    .then((formLists) => {
                        console.log(formLists);

                        setTaskDetail(response);
                        setClientList(formLists.ClientList);
                        setPersonList(formLists.PersonList);
                        setTeamList(formLists.TeamList);
                        // setMessageList(response.messages);
                        setIsLoading(false);
                    });            
                });            
        } else {
            console.log("Load template data for task_main.TaskId: " + id);

            execute("GET", protectedResources.apiTaskTemplate.endpoint+id)
                .then((response) => {

                    console.log('taskTemplate', response);

                    execute("GET", protectedResources.apiTaskFormLists.endpoint)
                    .then((formLists) => {
                        console.log(formLists);

                        let dateNow = date.format(new Date(), 'YYYY-MM-DD 00:00:00', false);

                        let objTaskDetail = {
                            WorkflowProcessId: null,
                            ProcessGroupId: null,
                            ProcessTypeId: null,
                            ParentId: null,
                            WorkflowId: null,
                            TaskId: response.TaskId,
                            TaskOrder: null,
                            TaskDuration: 1,
                            ClientId: response.ClientId,
                            TeamId: response.TeamId,
                            CreateDate: null,
                            CreatedBy: null,
                            AssignDate: null,
                            AssignedTo: null,
                            PriorityTypeId: null,
                            DueDate: dateNow,
                            StartDate: null,
                            StartedBy: null,
                            ProcessDate: null,
                            ProcessedBy: null,
                            CompleteDate: null,
                            CompletedBy: null,
                            TaskStatusTypeId: null,
                            LastStatusUpdate: null
                        }

                        setTaskDetail(objTaskDetail)
                        setClientList(formLists.ClientList);
                        setPersonList(formLists.PersonList);
                        setTeamList(formLists.TeamList);
                        // setMessageList(response.messages);
                        setIsLoading(false);
                    });            
                });  
        }

    }, [execute])
    
    useEffect(() => {
        console.log('isLoading', isLoading);
    }, [isLoading])

    useEffect(() => {
        console.log('agentEnabled', agentEnabled);
    }, [agentEnabled])

    useEffect(() => {
        console.log('editDisabled', editDisabled);
    }, [editDisabled])

    // useEffect(() => {
    //     console.log('createdByPerson', createdByPerson);
    // }, [createdByPerson])

    useEffect(() => {
        let storedAssignedTo = '';
        let storedResolvedBy = '';

        if (fs_list.includes('110') || fs_list.includes('111')) {
            setAgentEnabled(true);
        }

        if (taskDetail) {  // Existing Task
            console.log('taskDetail', taskDetail);
            console.log('messageList', messageList);

            setEditDisabled(true);

            taskDetail.DueDate = taskDetail.DueDate.replace('Z', '');
            let storedDueDate = date.format(new Date(taskDetail.DueDate), 'YYYY-MM-DD 00:00:00', false);

            let lookupStatus = '';
            if (taskDetail.TaskStatusTypeId !== null) {
                lookupStatus = statusList.find(lookup => lookup.StatusId === taskDetail.TaskStatusTypeId);
                setSelectedStatus( {label: lookupStatus.StatusName, value: lookupStatus.StatusId} );
            }

            let lookupTeam = '';
            if (taskDetail.TeamId !== null) {
                lookupTeam = teamList.find(lookup => lookup.TeamId === taskDetail.TeamId);
                setTeamName(lookupTeam.TeamName);
            }

            let lookupClient = '';
            if (taskDetail.AssignedTo !== null) {
                lookupClient = clientList.find(lookup => lookup.ClientId === taskDetail.ClientId);
                setSelectedClient( {label: lookupClient.ClientName, value: lookupClient.ClientId} );
            }

            let lookupDuration = '';
            if (taskDetail.AssignedTo !== null) {
                lookupDuration = durationList.find(lookup => lookup.value === taskDetail.TaskDuration);
                setSelectedDuration( {label: lookupDuration.label, value: lookupDuration.value} );
            }

            let lookupPerson1 = '';
            if (taskDetail.CreatedBy !== null) {
                lookupPerson1 = personList.find(lookup => lookup.PersonId === taskDetail.CreatedBy);
                setCreatedByPerson(lookupPerson1);
            }

            let lookupPerson2 = '';
            if (taskDetail.AssignedTo !== null) {
                lookupPerson2 = personList.find(lookup => lookup.PersonId === taskDetail.AssignedTo);
                setSelectedAssignTo( {label: lookupPerson2.LastName + ', ' + lookupPerson2.FirstName, value: lookupPerson2.PersonId} );
            }

            let lookupPerson3 = '';
            if (taskDetail.AssignedTo !== null) {
                lookupPerson3 = personList.find(lookup => lookup.PersonId === taskDetail.CompletedBy);
                setSelectedCompleteBy( {label: lookupPerson3.LastName + ', ' + lookupPerson3.FirstName, value: lookupPerson3.PersonId} );
            }


            // let storedStatus = statusList.find(lookup => lookup.value === clientRequest.RequestStatusTypeId);
            // let storedCategory = categoryList.find(lookup => lookup.value === clientRequest.CategoryTypeId);
            // let storedType = typeList.find(lookup => lookup.value === clientRequest.ClientRequestType);
            // let storedPriority = priorityList.find(lookup => lookup.value === clientRequest.PriorityTypeId);

            // if (clientRequest.AssignedTo !== null) {
            //     storedAssignedTo = teamList.find(lookup => lookup.value === clientRequest.AssignedTo);
            // }

            // if (clientRequest.ResolvedBy !== null) {
            //     storedResolvedBy = teamList.find(lookup => lookup.value === clientRequest.ResolvedBy);
            // }

            // setRequestStatus(clientRequest.RequestStatusTypeId);
            // setRequestCategory(clientRequest.CategoryTypeId);
            // setRequestType(clientRequest.ClientRequestType);
            // setRequestPriority(clientRequest.PriorityTypeId);
            // setRequestAssignedTo(clientRequest.AssignedTo);
            // setRequestResolvedBy(clientRequest.ResolvedBy);

            // if (clientRequest.AssignedDate !== null)
            //     setRequestAssignedDate(date.format(new Date(clientRequest.AssignedDate), 'MM/DD/YYYY', false));

            // if (clientRequest.ResolvedDate !== null)
            //     setRequestResolvedDate(date.format(new Date(clientRequest.ResolvedDate), 'MM/DD/YYYY', false));

            // setSelectedStatus( {label: storedStatus.label, value: storedStatus.value} );
            // setSelectedCategory( {label: storedCategory.label, value: storedCategory.value} );
            // setSelectedType( {label: storedType.label, value: storedType.value} );
            // setRequestSubject(clientRequest.Subject);
            // setRequestInstructions(clientRequest.Instructions);
            // setSelectedPriority( {label: storedPriority.label, value: storedPriority.value} );
            // setSelectedAssignedTo( {label: storedAssignedTo.label, value: storedAssignedTo.value} );
            // setSelectedResolvedBy( {label: storedResolvedBy.label, value: storedResolvedBy.value} );
            setSelectedDueDate(storedDueDate);
            // setRequestedBy(clientRequest.RequestBy);
            // setClientName(clientRequest.ClientName);

            // updateStatusColor(clientRequest.RequestStatusTypeId);

            // setMessageDisplay(messages);

            setSelectedTeamValue(taskDetail.TeamId);


        } else {  // New Task

            setRequestedBy(activeAccount.name);
            setEditDisabled(false);
            updateStatusColor(500);

            // if (clientRequest.AssignedTo !== null) {
            //     storedAssignedTo = teamList.find(lookup => lookup.value === clientRequest.AssignedTo);
            // }

        }
    }, [execute, taskDetail])

    
    useEffect(() => {

        messages = messageList.length > 0 ? (
            messageList.map(message => (
                <MessageItem
                    messageId={message.ClientMessageId}
                    messageText={message.MessageBody}
                    messageFrom={message.FirstName + ' ' + message.LastName}
                    messageTime={ date.format(new Date(message.TimeStamp), 'M/D/YY h:mm A', false) }
                />
            ))
        ) : (
            <></>
        );

        setMessageDisplay(messages);

    }, [execute, messageList])


    if (error) {
        return <div>Error: {error.message}</div>;
    }
    
    // --------------------------------------------------

    const handleInputSubject = (e) => {
        setRequestSubject(e.target.value);
    }
    const handleInputInstructions = (e) => {
        setRequestInstructions(e.target.value);
    }

    // --------------------------------------------------

    const setDueDate = date => {
        console.log('setDueDate >>', date);
        setSelectedDueDate(date);
    };

    const handleSelectStatus = (selectedStatus) => {
        setSelectedStatus(selectedStatus);
        setRequestStatus(selectedStatus.value);
        updateStatusColor(selectedStatus.value);
        if (selectedStatus !== null) {
            console.log('selectedStatus', selectedStatus);
            if (selectedStatus.value === 500) {
                setSelectedAssignedTo(null);
                setRequestAssignedDate(null);
                setSelectedResolvedBy(null);
                setRequestResolvedDate(null);
            }
        }
    }

    const handleSelectType = (selectedType) => {
        setSelectedType(selectedType);
        setRequestType(selectedType.value);
        if (selectedType !== null) {
            console.log('selectedType', selectedType);
        }
    }

    const handleSelectPriority = (selectedPriority) => {
        setSelectedPriority(selectedPriority);
        setRequestPriority(selectedPriority.value)
        if (selectedPriority !== null) {
            console.log('selectedPriority', selectedPriority);
        }
    }

    const handleSelectAssignedTo = (selectedAssignedTo) => {
        setSelectedAssignedTo(selectedAssignedTo);
        setRequestAssignedTo(selectedAssignedTo.value)
        setRequestAssignedDate(date.format(new Date(), 'MM/DD/YYYY', false));  //****
        if (selectedAssignedTo !== null) {
            console.log('selectedAssignedTo', selectedAssignedTo);
        }
    }

    const handleSelectResolvedBy = (selectedResolvedBy) => {
        setSelectedResolvedBy(selectedResolvedBy);
        setRequestResolvedBy(selectedResolvedBy.value)
        setRequestResolvedDate(date.format(new Date(), 'MM/DD/YYYY', false));  //****
        if (selectedResolvedBy !== null) {
            console.log('selectedResolvedBy', selectedResolvedBy);
        }
    }

    const updateStatusColor = (requestStatusTypeId) => {
        switch(requestStatusTypeId) {
            case 500:
                setStatusLabel("New Request")
                setStatusColor("#ffc107");
                break;
            case 501:
                setStatusLabel("Assigned")
                setStatusColor("#7da7d1");
                break;
            case 502:
                setStatusLabel("In Progress")
                setStatusColor("#b5eba2");
                break;
            case 510:
                setStatusLabel("Complete")
                setStatusColor("#3a9c6c");
                break;
        }
    }


    const handleSaveTask = (e) => {

        e.preventDefault();
        console.log('handleSaveTask');
        // console.log('--> save taskDetail', taskDetail);

        let dueDate = date.format(new Date(selectedDueDate), 'YYYY-MM-DD 00:00:00', true);
        
        // let assignedDate = null;
        // if (requestAssignedDate)
        //     assignedDate = date.format(new Date(requestAssignedDate), 'YYYY-MM-DD HH:mm:ss', true);

        // let resolvedDate = null;
        // if (requestResolvedDate)
        //     resolvedDate = date.format(new Date(requestResolvedDate), 'YYYY-MM-DD HH:mm:ss', true);

        // let requestId = 0;
        // let statusId = 500;
        // let assignedToId = null;
        // let resolvedToId = null;

        // if (taskDetail) {
        //     requestId = clientRequest.ClientRequestId;
        //     statusId = selectedStatus.value;
        //     if (selectedAssignedTo)
        //         assignedToId = selectedAssignedTo.value;
        //     if (selectedResolvedBy)
        //         resolvedToId = selectedResolvedBy.value;
        // }

        // const sendParams = {
        //     ClientRequestId: requestId,
        //     Category: selectedCategory.value,
        //     Type: selectedType.value,
        //     Subject: requestSubject,
        //     Instructions: requestInstructions,
        //     Priority: selectedPriority.value,
        //     AssignedTo: assignedToId,
        //     AssignedDate: assignedDate,
        //     ResolvedBy: resolvedToId,
        //     ResolvedDate: resolvedDate,
        //     DueDate: dueDate,
        //     RequestBy: activeAccount.localAccountId,
        //     RequestStatusTypeId: statusId
        // };



        const sendParams = {
            taskFormData: taskDetail
        };


        console.log('sendParams', sendParams);

        execute("POST", protectedResources.apiTask.endpoint, sendParams)
            .then((response) => {
                console.log(response);

                // handlePageBack();
            }
        );

        if (error) {
            return <div>Error: {error.message}</div>;
        }
    
        return <>done</>;

    };


    const handlePageBack = () => {
        navigate(-1);
    }
    

    // --------------------------------------------------
    // Previous version handlers
    // --------------------------------------------------

    const onChangeValue = () => {
        console.log('onChangeValue');
    };

    const handleChange1 = () => {
        console.log('handleChange1');
    };

    const handleChange2 = () => {
        console.log('handleChange2');
    };

    const handleChange4 = () => {
        console.log('handleChange4');
    };


    const handleChange5 = (selectedStatus) => {
        setSelectedStatus(selectedStatus);

        if (selectedStatus !== null) {
            console.log('selectedStatus', selectedStatus);

            setTaskDetail((prevState) => ({
                ...prevState,
                TaskStatusTypeId: selectedStatus.value,
            }));            

        }
    };


    const handleChange6 = (selectedDuration) => {
        setSelectedDuration(selectedDuration);

        if (selectedDuration !== null) {
            console.log('selectedDuration', selectedDuration);
        }
    };

    const handleKBLink = () => {
        console.log('handleKBLink');
    };

    const handleRecurrenceLink = () => {
        console.log('handleRecurrenceLink');
    };

    const handleCommentPopup = () => {
        console.log('handleCommentPopup');
    };

    // --------------------------------------------------


    const NewMessageDialog = () => {
        const [isOpen, setIsOpen] = useState(false);
        const [newMessageText, setNewMessageText] = useState('');
        const [isBlank, setIsBlank] = useState();

        const handleOpen = () => {
          setIsOpen(true);
        };
      
        const handleClose = () => {
          setIsOpen(false);
        };
        
        const handleMessageEntry = (event) => {
            setNewMessageText(event.target.value);
            if (event.target.value !== '')
                setIsBlank(false);
        };

        const handleSubmitMessage = (event) => {
          event.preventDefault();
          if (newMessageText.trim() === '') {
            setIsBlank(true);
          } else {
            console.log("newMessageText", newMessageText);

            let requestId = 0;

            if (clientRequest) {
                requestId = clientRequest.ClientRequestId;
            }

            const sendParams = {
                ClientRequestId: requestId,
                MessageBody: newMessageText,
            };
    
            // Add message
            execute("POST", protectedResources.apiNewRequestMessage.endpoint, sendParams)
                .then((response) => {
                    // Refresh message list
                    console.log(response);
                    setMessageList(response.messages);
                }
            );

            handleClose();
          }
        };
      
        return (
        <>
            <AddCircleIcon className="add-message" onClick={handleOpen} />
            {isOpen && (
            <div className="popup-dialog">
                <div className="popup-content">
                <h4>New Comment</h4>
                <form onSubmit={handleSubmitMessage}>
                    <Row>
                        <Col style={{ width: '400px', paddingTop: '10px' }}>
                        <FormGroup>
                            <Form.Control as="textarea" rows={4} value={newMessageText}
                                          autoFocus={true} onChange={handleMessageEntry} 
                                          tabIndex="9"
                                          style={{ backgroundColor:   
                                            isBlank ? '#FFC0CB' : '#ffffff'
                                          }}  
                            />
                        </FormGroup>     
                        </Col>
                    </Row>
                    <Button type="submit" variant="success" tabIndex="11" style={{ float: 'right' }}>Save</Button>
                </form>
                <Button variant="secondary" style={{ float: 'right', marginRight: '10px' }} 
                        tabIndex="10" onClick={handleClose}>Cancel</Button>
                </div>
            </div>
            )}
        </>
        );
      };     


    return (

        <div className="data-area-div">

            {/* <span id="fileCounter"></span> */}
            
            <Table borderless size="sm" className="svc-req-page">

            <Row>
                <Col className='col-12'>
                    <span>
                        <ArrowCircleLeftOutlinedIcon className="back-button" onClick={handlePageBack} />
                        <span style={{paddingLeft: '10px', fontSize: '1.5em', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            { (formMode === 'new') ? 'New' : 'Edit' } Task
                        </span>
                    </span>
                </Col>
            </Row>

            <Row>
                <Col className='col-12'>

                <Row>
                    <Col className='col-8'>

                        <Container>

                            <Form onSubmit={handleSaveTask}>

                            <Container className="form-scroll-area">

                                { taskDetail ? (

                                    // <p>----- Template Data Here -----</p>
                                    // <p>----- Data Here -----</p>

                                    <Container className='div-section'>

                                    <Row>
                                      <Col className='col-12' style={{marginLeft: '1px'}}>
                        
                        
                                        <FormGroup>
                                          <Row>
                                            <Col xs={6}>
                                              <Label for="TaskTitle" className='fz-gray-3'>Title</Label>
                                              <div className='static-form-value'>{taskDetail.TaskTitle || ''}</div>
                                            </Col>
                                            <Col xs={1}>
                                              <FormGroup className='form-spacing-3'>
                                                <div><br /><br /></div>
                                                {/* &nbsp; &nbsp;<span className={'pos-tweak-1 icon-status-'+taskDetail.TaskStatusTypeId}></span> */}
                                                &nbsp; &nbsp;<span className={'pos-tweak-1 icon-status-'}></span>
                                              </FormGroup>           
                                            </Col>
                                      
                                            <Col xs={5}>
                                              <FormGroup className='form-spacing-2' style={{ margin: '1px' }}>
                                                <div className='text-center'><Label className='fz-gray-3'>Status</Label></div>
                                                <Select 
                                                    tabIndex="2"
                                                    item="3"
                                                    maxMenuHeight={160}
                                                    value={selectedStatus}
                                                    onChange={handleChange5}
                                                    options={ statusList.map( opt => ({ label: opt.StatusName, value: opt.StatusId })) }
                                                />                                                
                                              </FormGroup> 
                                            </Col>
                                          </Row>
                                        </FormGroup>     
                                        
                                        <FormGroup className='form-spacing-2'>
                                          <Row>
                                            <Col xs={11}>
                                              <Label for="TaskDescription" className='fz-gray-3'>Description</Label>
                                              {/* <div className='static-form-value'>{taskTemplate.TaskDescription || ''}</div> */}
                                              <div className='static-form-value'>{taskDetail.TaskDescription || ''}</div>
                                            </Col>
                                            <Col xs={1} style={{paddingTop: '60px', paddingLeft: '5px'}}>
                                                <div className={(kbFileId !== null && kbFileId !== undefined) ? "icon-kb-green icon-knowledgebase-link" : "icon-kb-gray icon-knowledgebase-link"} 
                                                    title={(kbFileId !== null && kbFileId !== undefined) ? "Documentation" : "No Documentation"}
                                                    onClick={(kbFileId !== null && kbFileId !== undefined) ? () => handleKBLink() : () => console.log()}>&nbsp; &nbsp; &nbsp;</div>
                                            </Col>
                                          </Row>
                                        </FormGroup>               
                        
                                        <Row>
                                          <Col>
                                            <FormGroup className='form-spacing-2'>
                                              <Label for="TeamTitle" className='fz-gray-3'>Team</Label>
                                              <div className='static-form-value'>{teamName || ''}</div>
                                            </FormGroup>                    
                                          </Col>
                                        </Row>
                        
                                        <Row>
                                          <Col xs={6}>
                                            <FormGroup className='form-spacing-2'>
                                              <Label for="Client" className='fz-gray-3'>Client</Label>
                                              <Select
                                                value={selectedClient}
                                                onChange={handleChange1}
                                                options={ clientList.map( opt => ({ label: opt.ClientName, value: opt.ClientId })) }
                                              />
                                            </FormGroup>           
                                          </Col>
                        
                                          <Col className='col-6 align-center'>
                                              <FormGroup className='form-spacing-2'>
                                                <div>
                                                  <Label for="Priority" className='fz-gray-3'>Priority</Label>&nbsp;
                                                </div>
                                                <ButtonGroup onClick={onChangeValue} value={toggleTaskPriority}>
                                                  <Button type='button' value='1' 
                                                          className='toggle-button' color={toggleTaskPriority === 1 ? 'primary' : 'secondary'}
                                                          >Low</Button>
                                                  <Button type='button' value='2' 
                                                          className='toggle-button' color={toggleTaskPriority === 2 ? 'primary' : 'secondary'}
                                                          >Medium</Button>
                                                  <Button type='button' value='3' 
                                                          className='toggle-button' color={toggleTaskPriority === 3 ? 'primary' : 'secondary'}
                                                          >High</Button>
                                                </ButtonGroup>
                                              </FormGroup>
                                            </Col>                  
                                        </Row>
                        
                                          <Row>
                                            <Col className='col-3'>
                                              <Form.Group style={{ float: 'right' }}>
                                                <InputGroup className='form-spacing-2'hasValidation>
                                                  <div>
                                                    <Label for="DueDate" className='fz-gray-3'>Due Date</Label>
                                                  </div>
                                                  <DatePicker selected={selectedDueDate} onChange={(date) => setDueDate(date)} 
                                                              className='date-picker fz-gray-2' todayButton="Today"
                                                              required
                                                              // valid={ taskDueDateState === 'has-success' }
                                                              // invalid={ taskDueDateState === 'has-danger' }
                                                  />
                                                </InputGroup>  
                                                <Form.Control.Feedback type="invalid">
                                                    Please select a Due Date
                                                </Form.Control.Feedback>                                        
                                              </Form.Group>
                                            </Col>
                        
                                            <Col className='col-3'>
                                              <FormGroup className='form-spacing-2'>
                                                <div>
                                                  <Label for="Duration" className='fz-gray-3'>Duration (days)</Label>
                                                </div>

                                                <Select 
                                                    tabIndex="2"
                                                    item="3"
                                                    maxMenuHeight={160}
                                                    value={selectedDuration}
                                                    onChange={handleChange6}
                                                    options={ durationList.map( opt => ({ label: opt.label, value: opt.value })) }
                                                />                                                 
                                                  
                                              </FormGroup>                    
                                            </Col>
                        
                                            <Col className='col-6 align-center'>
                                              <FormGroup className='form-spacing-2'>
                                                <div>
                                                  <Label for="RecurringButton" className='fz-gray-3'>&nbsp;</Label>&nbsp;
                                                </div>
                                                <Button color="btn btn-success" onClick={() => handleRecurrenceLink()}>Setup Repeating Task</Button>
                                              </FormGroup>
                                            </Col>
                                          </Row>
                        
                                          <Row>
                                            <Col>
                                              <FormGroup className='form-spacing-2'>
                                                <Label className='fz-gray-3'>Created By</Label>
                                                <div className='static-form-value'>
                                                    {createdByPerson ? createdByPerson.FirstName + ' ' + createdByPerson.LastName : ''}
                                                    </div>
                                              </FormGroup>                    
                                            </Col>
                        
                                            <Col>
                                              <FormGroup className='form-spacing-2'>
                                                <div className='text-center'><Label className='fz-gray-3'>Created Date</Label></div>
                                                <div className='static-form-value td-center-h'>{displayCreateDate || ''}</div>
                                              </FormGroup>                    
                                            </Col>
                                          </Row>
                        
                                          <Row>
                                            <Col>
                                              <FormGroup className='form-spacing-2'>
                                                <Label for="AssignedToPerson" className='fz-gray-3'>Assigned To</Label>
                                                <Select 
                                                  value={selectedAssignTo}
                                                  onChange={handleChange2}
                                                  options={ personList.map( opt => ({ label: opt.LastName + ', ' + opt.FirstName, value: opt.PersonId })) }
                                                />
                                              </FormGroup>                    
                                            </Col>
                        
                                            <Col>
                                              <FormGroup className='form-spacing-2'>
                                                <div className='text-center'><Label className='fz-gray-3'>Assigned Date</Label></div>
                                                <div className='static-form-value td-center-h'>{displayAssignDate || ''}</div>
                                              </FormGroup>                    
                                            </Col>
                                          </Row>
                        
                        
                                          <Row>
                                            <Col>
                                              <FormGroup className='form-spacing-2'>
                                                <Label for="ApprovalByPerson" className='fz-gray-3'>To be Approved By</Label>
                        
                                                <Select
                                                    value={selectedCompleteBy}
                                                    onChange={handleChange4}
                                                    options={ personList.map( opt => ({ label: opt.LastName + ', ' + opt.FirstName, value: opt.PersonId })) }
                                                />
                                              </FormGroup>                    
                                            </Col>
                        
                                            <Col>
                                              <FormGroup className='form-spacing-2'>
                                                <div className='text-center'><Label className='fz-gray-3'>Approved Date</Label></div>
                                                <div className='static-form-value td-center-h'>{displayCompleteDate || ''}</div>
                                              </FormGroup>                    
                                            </Col>
                                          </Row>
                        
                        
                                        <div className='divSpacer'></div>
                        
                                        {/* <TemplateFiles sourceId={this.state.paramTemplateTaskId} 
                                                      componentLabel='Template Files' allowChanges='false' /> */}
                        
                                        {/* Error (state update) this.props.params.id */}
                        
                                        {/* <TaskFiles sourceId={this.state.paramWorkflowProcessId} 
                                                  componentLabel='Task Files' allowChanges='true' /> */}
                        
                                    </Col>
                        
                        
                                      {/* <Col className='col-4' style={{marginLeft: '-1px'}}>
                        
                                        <div className="message-area message-scroll-area">
                                          <div className="align-center">
                                            <Container>
                                              <Row>
                                                <Col className="col-12">
                                                  <span className="comment-count"><br />Comments ({taskComments.length})</span>
                                                </Col>
                                              </Row>
                                              <Row>
                                                <Col className="col-12">
                                                  <Button className="comment-button" color="success" 
                                                        //   disabled={isCommentDisabled}
                                                          onClick={handleCommentPopup}>New Comment</Button>
                                                </Col>
                                              </Row>
                                            </Container>
                                          </div>
                        
                                          {taskComments}
                        
                                        </div>
                        
                                      </Col> */}
                        
                                    </Row>
                        
                        
                                  </Container>                                    

                                ) : 

                                <></>

                                }

                            </Container>

                            
                            <Row>
                                <Col className='col-2'>
                                    &nbsp;
                                </Col>
                                <Col className='col-8'>
                                    {/* { (editDisabled && agentEnabled) || (!editDisabled && agentEnabled) ?  */}
                                    { !taskDetail || agentEnabled ?  
                                        <div>
                                            {/* <span style={{ display: 'flex', float: 'left', paddingTop: '15px' }}>
                                                <Button variant='secondary' type="reset"
                                                        style={{ width: '100px' }} tabIndex="7">
                                                    Reset
                                                </Button>                  
                                            </span> */}
                                            <span style={{ display: 'flex', float: 'right', paddingTop: '15px' }}>
                                                <Button variant="success" type="submit"
                                                        disabled={editDisabled && !agentEnabled}
                                                        style={{ width: '100px' }} tabIndex="8">
                                                    Submit
                                                </Button>                  
                                            </span>
                                        </div>
                                        : 
                                        <></>
                                    }                                    

                                </Col>
                            </Row>

                            </Form>

                        </Container>
                    </Col>

                    <>

                    </>
                    {/* {taskDetail ?  */}
                        <Col className='col-4'>
                            <div className="message-area message-scroll-area" style={{ padding: '10px', textAlign: 'center' }}>
                                <div style={{ paddingBottom: '10px' }}>
                                    {/* <span className="messages-header">Comments {messageList.length > 0 ? '('+messageList.length+')' : ''}</span> */}
                                    <NewMessageDialog />
                                </div>
                                <ListGroup>
                                    {messageDisplay}
                                </ListGroup>
                            </div>
                        </Col>
                    {/* : null} */}

                </Row>

                </Col>

                </Row>

            </Table>
           
        </div>
    );
}


export const MessageItem = (props) => {

    const handleClickItem = (id) => {
        console.log('handleClickItem', id);
        // navigate('/service-request/'+id);
    }

    const viewTemplate = (
        <div key={props.messageId} style={{ paddingTop: '5px', marginBottom: '-10px' }}>
            <div>
                <span className="message-from">{props.messageFrom}</span>
                <span className="message-time">{props.messageTime}</span>
            </div>
            <div className="message-text">
                {props.messageText}
            </div>
        </div>
    );

    return <ListGroup.Item key={props.messageId} onClick={() => handleClickItem(props.messageId)}
                           style={{ minHeight: '100px' }}>
                {viewTemplate}
            </ListGroup.Item>;
}
