import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import styles from "./sidenav.module.css"
import { NavLink } from "react-router-dom";
import MenuIcon from '@mui/icons-material/MenuOutlined';
import { NavList } from "../../common/nav-links"
import { NavListClient } from "../../common/nav-links-client"


export default function SideNav({toggleNavCollapse}) {

  const { instance, inProgress } = useMsal();
  let activeAccount;
  let fs_list = [];

  if (instance) {
      activeAccount = instance.getActiveAccount();
      let ext_fs = activeAccount.idTokenClaims.extension_fs;

      if (ext_fs !== null)
          fs_list = ext_fs.split(' ');

      console.log("fs_list", fs_list);
      console.log( fs_list.includes('503') );
  }

  const [open, setopen] = useState(true)
  const toggleOpen = () => {
      setopen(!open);
      toggleNavCollapse();
  }
    
  return (

    <AuthenticatedTemplate>

      <div className={open ? styles.sidenav : styles.sidenavClosed}>

        <div className={styles.menuBtn} onClick={toggleOpen}>
          {open ? <MenuIcon style={{color: "#d4d4d4"}} /> : <MenuIcon style={{color: "#2B3B36"}} />}
        </div>

        {fs_list.includes('102') ? (
          <div>
            {NavList.map(item =>{
              return <NavLink key={item.id} className={styles.sideitem} to={item.link}>
                {item.icon}
                <span className={styles.linkText}>{item.text}</span>
              </NavLink>
            })}
          </div>
        ): <></>}

        {fs_list.includes('202') || fs_list.includes('502') ? (
          <div>
            {NavListClient.map(item =>{
              return <NavLink key={item.id} className={styles.sideitem} to={item.link}>
                {item.icon}
                <span className={styles.linkText}>{item.text}</span>
              </NavLink>
            })}
          </div>
        ): <></>}

      </div>

    </AuthenticatedTemplate>

  )
}