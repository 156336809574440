import { useState } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { NavigationBar } from "./NavigationBar";
import SideNav from './SideNav';
import styles from "./sidenav.module.css"

export const PageLayout = (props) => {

    // const [isNavClosed, setIsNavClosed] = useState(props.isNavClosed);
    // console.log('PageLayout - isNavClosed', props.isNavClosed);

    return (
        <>
            <NavigationBar instance={props.instance} notificationAllowed={props.notificationAllowed} />

            <UnauthenticatedTemplate>
                <div className="landing-page">
                    <div className="welcome-message">
                        <span>
                            <h2>
                                <center>Welcome</center>
                            </h2>
                        </span>
                        <br />
                        <br />
                        <h4>
                            <center>Sign in to continue</center>
                        </h4>
                    </div>
                </div>                
            </UnauthenticatedTemplate>

            <AuthenticatedTemplate>
                {/* <div className={`main-div ${isNavClosed ? 'expanded' : ''}`}> */}
                <div className={`main-div ${props.isNavClosed ? 'expanded' : ''}`}>
                    {props.children}
                </div>

                <div className={styles.poweredBy}>
                Powered by Finzen™
                </div>
            </AuthenticatedTemplate>
        </>
    );
}