import React, { useState, useRef, useEffect } from "react";
import {Routes, Route, useNavigate} from 'react-router-dom';
import { Link } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { nanoid } from "nanoid";

import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { protectedResources } from "../authConfig";

import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import { Container, FormGroup, Label } from 'reactstrap';
import { Input, Table, Row, Col } from 'reactstrap';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { Collapse, CardBody, Card, CardHeader } from "reactstrap";
import date from 'date-and-time'; 
import DatePicker from "react-datepicker";
import Select from 'react-select';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

function usePrevious(value) {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
}

export const TaskListView = (props) => {
    const { instance } = useMsal();
    const account = instance.getActiveAccount();

    const { error, execute } = useFetchWithMsal({
        scopes: protectedResources.apiTaskList.scopes.write
    });

    const [tasks, setTasks] = useState(props.taskData.TaskList);
    const [pgRecordCount, setPgRecordCount] = useState(props.taskData.RecordCount);
    const [pgPageSize, setPgPageSize] = useState(props.taskData.PageSize);
    const [pgPageNumber, setPgPageNumber] = useState(null);
    const [pgPageTotalPages, setPgPageTotalPages] = useState(null);
    const [pgStartPage, setPgStartPage] = useState(null);
    const [pgEndPage, setPgEndPage] = useState(null);
    const [pgPages, setPgPages] = useState([]);

    const [sectionsToggled, setSectionsToggled] = useState([]);
    const [showAdminContent, setShowAdminContent] = useState(null);
    const [searchWord, setSearchWord] = useState(null);
    const [isFilterOn, setIsFilterOn] = useState(false);
    const [selectedDateFrom, setSelectedDateFrom] = useState(null);
    const [filterTitle, setFilterTitle] = useState(null);
    const [selectedDateTo, setSelectedDateTo] = useState(null);
    const [selectedClient, setSelectedClient] = useState(null);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [teamList, setTeamList] = useState([]);
    const [clientList, setClientList] = useState([]);
    const [selectedPriority, setSelectedPriority] = useState(null);
    const [priorityList, setPriorityList] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [selectedCreatedBy, setSelectedCreatedBy] = useState(null);
    const [statusList, setStatusList] = useState([]);
    const [personList, setPersonList] = useState([]);
    const [selectedAssignedTo, setSelectedAssignedTo] = useState(null);
    const [selectedApprovedBy, setSelectedApprovedBy] = useState(null);
    const [filterMyItems, setFilterMyItems] = useState(null);
    const [filterHideCompleted, setFilterHideCompleted] = useState(null);
    const [selectedPageSize, setSelectedPageSize] = useState(null);
    const [pageSizeList, setPageSizeList] = useState([]);
    const [isCalendarView, setIsCalendarView] = useState(true);
    const [dateRangeOption, setDateRangeOption] = useState(null);
    const [sortColumn, setSortColumn] = useState(null);
    const [sortAscending, setSortAscending] = useState(null);
    const [popoverOpen, setPopoverOpen] = useState(null);
    


    let activeAccount;
    let fs_list = [];

    if (instance) {
        activeAccount = instance.getActiveAccount();
        let ext_fs = activeAccount.idTokenClaims.extension_fs;

        if (ext_fs !== null) {
            fs_list = ext_fs.split(' ');
            // console.log("fs_list", fs_list);
        }
    }

    const navigate = useNavigate();


    // const taskList = tasks.length > 0 ? (
    //     tasks.map(task => (
    //         <TaskItem
    //             key={task.WorkflowProcessId}
    //             id={task.WorkflowProcessId}
    //             TaskId={task.TaskId}
    //             ClientId={task.ClientId}
    //             TeamId={task.TeamId}
    //             TaskStatus={task.TaskStatusTypeId}
    //             CreateDate={ date.format(new Date(task.CreateDate), 'M/D/YYYY h:mm A', false) }
    //         />
    //     ))
    // ) : (
    //     <div className='td-center-h'>No data available</div>
    // );


    const listHeadingRef = useRef(null);
    const prevListLength = usePrevious(tasks.length);

    useEffect(() => {
        if (tasks.length - prevListLength === -1) {
            listHeadingRef.current.focus();
        }
        console.log("useEffect --- 1 ---");
        getDueDateList();
    }, [tasks.length, prevListLength]);

    if (error) {
        return <div>Error: {error.message}</div>;
    }
    


    const handleSectionToggle = () => {
        console.log('handleSectionToggle');
    };

    const handleListItemClick = (workflowProcessId) => {
        console.log('handleListItemClick');
        // this.props.navigation('/task/edit/' + workflowProcessId);
        navigate('/task/'+workflowProcessId);
    };

    const handleSearch = () => {
        console.log('handleSearch');
    };

    const handleChange = () => {
        console.log('handleChange');
    };

    const toggle = () => {
        console.log('toggle');
    };

    const setDateFrom = () => {
        console.log('setDateFrom');
    };

    const setDateTo = () => {
        console.log('setDateTo');
    };

    const handleChange1 = () => {
        console.log('handleChange1');
    };

    const handleChange2 = () => {
        console.log('handleChange2');
    };

    const handleChange3 = () => {
        console.log('handleChange3');
    };

    const handleChange4 = () => {
        console.log('handleChange4');
    };

    const handleChange5 = () => {
        console.log('handleChange5');
    };

    const handleChange6 = () => {
        console.log('handleChange6');
    };

    const handleChange7 = () => {
        console.log('handleChange7');
    };

    const handleChange8 = () => {
        console.log('handleChange8');
    };

    const handleChange9 = () => {
        console.log('handleChange9');
    };

    const handleChange10 = () => {
        console.log('handleChange10');
    };

    const handleClearFilter = () => {
        console.log('handleChange7');
    };

    const handleMyItemsToggle = () => {
        console.log('handleMyItemsToggle');
    };

    const handleHideCompletedToggle = () => {
        console.log('handleHideCompletedToggle');
    };

    const handleClearFilters = () => {
        console.log('handleClearFilters');
    };
    
    const handleListViewChange = () => {
        console.log('handleListViewChange');
    };

    const handleDateToggle = () => {
        console.log('handleDateToggle');
    };
    
    const handleListSort = () => {
        console.log('handleListSort');
    };


    const getDueDateList = () => {
        let taskCount = tasks.length;
        let lastDueDateTag = '';
    
        for (let i = 0; i < taskCount; i++) {
          let currentTask = tasks[i];
          let taskDueDate = new Date(currentTask.DueDate.replace("Z", ""));
          let dueDateTag = date.format(taskDueDate, 'YYYYMMDD', false);
    
          if (dueDateTag !== lastDueDateTag) {

            // Add section to toggled list
            // this.setState(prevState => ({
            //   sectionsToggled: [...prevState.sectionsToggled, dueDateTag]
            // }), () => {
            //     // console.log('pgPages:', this.state.pgPages);
            //   }
            // ); 

            setSectionsToggled((prevSections) => [...prevSections, dueDateTag]);

          }
    
          lastDueDateTag = dueDateTag;
        }        
    };


    if (fs_list.includes('110') || fs_list.includes('111')) {
            

        // Loop through records and create header/detail sections
        let taskListData = [];
        let taskCount = tasks.length;
        let lastDueDateTag = '';
        let isSectionComplete = true;

        let taskList = null;

        for (let i = 0; i < taskCount; i++) {
            let currentTask = tasks[i];
            // let taskDueDate = new Date(currentTask.DueDate);
            let taskDueDate = new Date(currentTask.DueDate.replace("Z", ""));
            let dueDateTag = date.format(taskDueDate, 'YYYYMMDD', false);
            const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
            let taskWeekday = weekday[taskDueDate.getDay()];
            let dueDateHeader = taskWeekday + ", " + date.format(taskDueDate, 'M/D/YY', false);

            if (dueDateTag === lastDueDateTag) {

                if (currentTask.TaskStatusTypeId !== 3) {

                    let targetSection = taskListData.find(lookup => lookup.DueDateTag === dueDateTag);
                    if (targetSection !== null) {
                        if (targetSection.SectionComplete === true) {
                        targetSection.SectionComplete = false;
                        }
                    }

                }

                // Add row to section
                taskListData.forEach(element => {
                    if (element.DueDateTag === dueDateTag) {
                        element.Tasks.push(currentTask);
                    }
                });

            } else {

                isSectionComplete = true;

                if (currentTask.TaskStatusTypeId !== 3) {
                    isSectionComplete = false;
                }

                // Add section and current row
                taskListData.push({"DueDateTag": dueDateTag, 
                                "DueDateHeader": dueDateHeader, 
                                "SectionComplete": isSectionComplete,
                                "Tasks": [currentTask]});
            }

            lastDueDateTag = dueDateTag;

        }



        taskList = taskListData.map(taskSection => {

            let dueDateNow = new Date();
            let dueDateHeader = new Date(taskSection.DueDateHeader);
    
    
              return ( 
                <Card className="workflowListItem" key={taskSection.DueDateTag}>
                  <CardHeader className={taskSection.SectionComplete === true ? "card-header-bg-complete" : (dueDateHeader < dueDateNow ? "card-header-bg-overdue" : "card-header-bg") }
                              onClick={() => handleSectionToggle(taskSection.DueDateTag)}>
                    <section className="d-flex">
                      <CalendarMonthIcon style={{ width: '30px', height: '30px', marginRight: '10px', color: '#999999' }} />
                      <section className='section-text p-2'>
                        Due {taskSection.DueDateHeader}
                      </section>
    
                      <section className='section-text p-2'>
                        <div className={(dueDateHeader < dueDateNow) && (taskSection.SectionComplete === false) ? "statusOverdue" : "hidden-element"}>&nbsp; Overdue</div>
                      </section>
    
                      <section className='section-text ms-auto p-2'>
                        {taskSection.Tasks.length} {taskSection.Tasks.length > 1 ? 'Tasks' : 'Task'}
                      </section>
                    </section>
                  </CardHeader>
    
                  <Collapse isOpen={sectionsToggled.some(item => taskSection.DueDateTag === item)}>
                    <CardBody className='d-flex flex-column'>
    
                      {taskSection.Tasks.map(task => {
    
                        // let taskDueDate = new Date(task.DueDate);
                        let taskDueDate = new Date(task.DueDate.replace("Z", ""));
                        let isTaskOverdue = (new Date() > taskDueDate) && (task.TaskStatusTypeId !== 3);
                        let displayDueDate = date.format(taskDueDate, 'M/D/YY', false);
                        let assignedToName = '';
    
                        if (task.LastName != null) {
                          assignedToName = task.LastName + ', ' + task.FirstName;
                        }
    
                        let displayAssignDate = "";
                        if (task.AssignDate != null) {
                          displayAssignDate = date.format(new Date(task.AssignDate), 'M/D/YY h:mm A', false);
                        }
    
                        return <tbody key={task.WorkflowProcessId}><tr className='d-flex list-item task-item-padding' 
                              onClick={() => handleListItemClick(task.WorkflowProcessId) }>
                            <td className='td-30pct'>
                              <section>{task.TaskTitle}</section>
                              <section className='gray-2'>{task.TaskDescription}</section>
                              <section className='gray-2 font-italic'>{task.ClientName}</section>
                            </td>
                            <td className='td-20pct td-center-v'>{task.TeamName || ''}</td>
                            <td className='td-20pct td-center-v'>{assignedToName || ''}<br />{displayAssignDate || ''}</td>
                            <td className={isTaskOverdue === true ? "td-10pct td-center-v statusOverdue" : "td-10pct td-center-v"}>
                              {displayDueDate || ''}</td>
                            <td className='td-10pct td-center-v td-center-h'><span style={{color: '#000000'}}>{task.PriorityTypeId}</span></td>
                            <td className='td-10pct td-center-v td-center-h pos-tweak-2'>
                              <span className={'icon-status-'+task.TaskStatusTypeId}></span>
                            </td>
                          </tr>
                          </tbody>
                      })}
    
                    </CardBody>
                  </Collapse>
                </Card>
                
              ) 
    
          });



        return (
            // <div>
            //     <ListGroup>
            //         {taskList}
            //     </ListGroup>
            // </div>

            // <>{taskList}</>



            <div>
            <Container fluid>
    
              <Row>
                <Col className="mainDiv">
    
                    <Table className="x-table-head">
                      <thead>
                        <tr>
                          <th className="td-30pct fz-gray-3">
                            <span className={isCalendarView === false ? 'hidden-element' : 'icon-sort-padding'}>Description</span>
    
                            <span className={isCalendarView === true ? 'hidden-element' : 'cursor-pointer'} onClick={handleListSort} data-sort-column='TaskTitle'>Description</span>
                            <span className={isCalendarView === true ? 'hidden-element' : 'icon-sort-padding'}>
                              <span className={sortColumn === 'TaskTitle' && sortAscending === true ? 'icon-sort-up' : sortColumn === 'TaskTitle' && sortAscending === false ? 'icon-sort-down' : 'icon-sort-off' }
                                    onClick={handleListSort} data-sort-column='TaskTitle'>&nbsp; &nbsp; &nbsp;</span>
                            </span>
                          </th>
                          <th className="td-20pct fz-gray-3">
                            <span className={isCalendarView === false ? 'hidden-element' : 'icon-sort-padding'}>Team</span>
    
                            <span className={isCalendarView === true ? 'hidden-element' : 'cursor-pointer'} onClick={handleListSort} data-sort-column='TeamName'>Team</span>
                            <span className={isCalendarView === true ? 'hidden-element' : 'icon-sort-padding'}>
                              <span className={sortColumn === 'TeamName' && sortAscending === true ? 'icon-sort-up' : sortColumn === 'TeamName' && sortAscending === false ? 'icon-sort-down' : 'icon-sort-off' }
                                  onClick={handleListSort} data-sort-column='TeamName'>&nbsp; &nbsp; &nbsp;</span>
                            </span>
                          </th>
                          <th className="td-15pct fz-gray-3">
                            <span className={isCalendarView === false ? 'hidden-element' : 'icon-sort-padding'}>Assigned To</span>
    
                            <span className={isCalendarView === true ? 'hidden-element' : 'cursor-pointer'} onClick={handleListSort} data-sort-column='LastName'>Assigned To</span>
                            <span className={isCalendarView === true ? 'hidden-element' : 'icon-sort-padding'}>
                              <span className={sortColumn === 'LastName' && sortAscending === true ? 'icon-sort-up' : sortColumn === 'LastName' && sortAscending === false ? 'icon-sort-down' : 'icon-sort-off' }
                                  onClick={handleListSort} data-sort-column='LastName'>&nbsp; &nbsp; &nbsp;</span>
                            </span>
                          </th>
                          <th className="td-15pct fz-gray-3">
                            <span className={isCalendarView === false ? 'hidden-element' : 'icon-sort-padding'} style={{paddingLeft: 20}}>Due Date</span>
    
                            <span className={isCalendarView === true ? 'hidden-element' : 'cursor-pointer'} style={{paddingLeft: 20}} onClick={handleListSort} data-sort-column='DueDate'>Due Date</span>
                            <span className={isCalendarView === true ? 'hidden-element' : 'icon-sort-padding'}>
                              <span className={sortColumn === 'DueDate' && sortAscending === true ? 'icon-sort-up' : sortColumn === 'DueDate' && sortAscending === false ? 'icon-sort-down' : 'icon-sort-off' }
                                  onClick={handleListSort} data-sort-column='DueDate'>&nbsp; &nbsp; &nbsp;</span>
                            </span>
                          </th>
                          <th className="td-10pct fz-gray-3">
                            <span className={isCalendarView === false ? 'hidden-element' : 'icon-sort-padding'}>Priority</span>
    
                            <span className={isCalendarView === true ? 'hidden-element' : 'cursor-pointer'} onClick={handleListSort} data-sort-column='PriorityTypeId'>Priority</span>
                            <span className={isCalendarView === true ? 'hidden-element' : 'icon-sort-padding'}>
                              <span className={sortColumn === 'PriorityTypeId' && sortAscending === true ? 'icon-sort-up' : sortColumn === 'PriorityTypeId' && sortAscending === false ? 'icon-sort-down' : 'icon-sort-off' }
                                  onClick={handleListSort} data-sort-column='PriorityTypeId'>&nbsp; &nbsp; &nbsp;</span>
                            </span>
                          </th>
                          <th className="td-10pct fz-gray-3">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                      </tbody>
                    </Table>
    
                    <div className="list-scroll-area">
    
                      <Table> 
                        {taskList}
                      </Table>
    
                    </div>
    
                </Col>
              </Row>
    
    
            </Container>
          </div>            



        );
    } else {
        return <div>Error: Module not available</div>; 
    }
}


export const TaskItem = (props) => {

    const navigate = useNavigate();

    const handleClickItem = (id) => {
        console.log('handleClickItem', id);

        // this.props.navigation('/todolist/'+categoryTypeId);
        navigate('/task/'+id);
    }

    let statusColor = '#bababa';

    switch(props.TaskStatus) {
        case 0:
            statusColor = "#ffc107";
            break;
        case 1:
            statusColor = "#7da7d1";
            break;
        case 2:
            statusColor = "#b5eba2";
            break;
        case 3:
            statusColor = "#3a9c6c";
            break;
    }

    const viewTemplate = (

        <div onClick={() => handleClickItem(props.id)}
             key={props.id} style={{ paddingTop: '5px', marginBottom: '-20px' }}>
                
            <Table className="task-props">
                <tr>
                    <td style={{ width: '60%' }}>
                        <span className="list-label" style={{ fontWeight: 'bold' }}>
                            {props.TaskId} | {props.ClientId} | {props.TeamId} 
                        </span>
                    </td>
                    <td style={{ width: '10%' }}>
                        &nbsp;
                    </td>
                    <td style={{ width: '30%' }}>
                        <span className="list-label status-assigned" style={{ backgroundColor: statusColor }}>
                            {props.TaskStatus}
                        </span>
                    </td>                            
                </tr>
                <tr>
                    <td>
                        <span className="list-label">
                            {props.Subject}
                        </span>
                    </td>
                    <td>
                        <span className="list-label" />
                    </td>
                    <td className="td-right-h">
                        <span className="list-label" />
                    </td>                            
                </tr>
                <tr>
                    <td>
                        <span className="list-label">
                            {props.RequestBy}
                        </span>
                    </td>
                    <td>
                        <span className="list-label" />
                    </td>
                    <td className="td-right-h">
                        <span className="list-label">
                            {props.CreateDate}
                        </span>
                    </td>                            
                </tr>
            </Table>
        </div>
        
    );

    return <ListGroup.Item>{viewTemplate}</ListGroup.Item>;
}