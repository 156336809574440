import React, { useState, useRef, useEffect } from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";
import { Table, Row, Col, Container, Tab } from "react-bootstrap";
import { IdTokenData } from "../components/DataDisplay";

import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { protectedResources } from "../authConfig";
import { ClientProfileView, ClientLogo } from '../components/ClientProfileView';

import { Data } from "./Data";
import BarGraph from './BarChart';
import LineChart from "./BarChart";
import ListGroup from "react-bootstrap/ListGroup";
import date from 'date-and-time'; 

import ScrollingTable from './table1';
import { RecentRequests } from './RecentRequests';

/***
 * Component to detail ID token claims with a description for each claim. For more details on ID token claims, please check the following links:
 * ID token Claims: https://docs.microsoft.com/en-us/azure/active-directory/develop/id-tokens#claims-in-an-id-token
 * Optional Claims:  https://docs.microsoft.com/en-us/azure/active-directory/develop/active-directory-optional-claims#v10-and-v20-optional-claims-set
 */
export const Home = () => {
    
    const { instance } = useMsal();    
    const activeAccount = instance.getActiveAccount();

    const { error, execute } = useFetchWithMsal({
        scopes: protectedResources.apiClientRequest.scopes.write
    });

    const [messageList, setMessageList] = useState([
        {
            "ClientMessageId": 1,
            "MessageBody": "First message is short and should not extend the message box.",
            "TimeStamp": "2024-03-23T00:33:56.000Z",
            "FirstName": "Item",
            "LastName": "One"
        },
        {
            "ClientMessageId": 2,
            "MessageBody": "Second message is longer and will eventually have to enlarge its containing div. Maximum size for text is 1000 characters.",
            "TimeStamp": "2024-03-23T00:42:56.000Z",
            "FirstName": "Item",
            "LastName": "Two"
        },
        {
            "ClientMessageId": 3,
            "MessageBody": "bbbb",
            "TimeStamp": "2024-03-23T14:34:50.000Z",
            "FirstName": "Item",
            "LastName": "Three"
        },
        {
            "ClientMessageId": 4,
            "MessageBody": "cccccc",
            "TimeStamp": "2024-03-23T14:40:28.000Z",
            "FirstName": "Item",
            "LastName": "Four"
        },
        {
            "ClientMessageId": 5,
            "MessageBody": "dddd",
            "TimeStamp": "2024-03-23T14:45:44.000Z",
            "FirstName": "Item",
            "LastName": "Five"
        },
    ]);

    const [messageDisplay, setMessageDisplay] = useState(null);

    let dateToday = date.format(new Date(), 'MMMM D, YYYY', false);

    let messages = [];

    const [chartData, setChartData] = useState({
        // ...chart data
    });

    // useEffect(() => {
    //     setMessageDisplay(messages);
    // }, [execute])


    useEffect(() => {

        messages = messageList.length > 0 ? (
            messageList.map(message => (
                <MessageItem
                    messageId={message.ClientMessageId}
                    messageText={message.MessageBody}
                    messageFrom={message.FirstName + ' ' + message.LastName}
                    messageTime={ date.format(new Date(message.TimeStamp), 'M/D/YY h:mm A', false) }
                />
            ))
        ) : (
            <></>
        );

        setMessageDisplay(messages);

    }, [execute, messageList])


    return (
        <>
            <AuthenticatedTemplate>
                {
                    activeAccount ?
                    // <Container className="page-content" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                    //     {/* <IdTokenData idTokenClaims={activeAccount.idTokenClaims} /> */}
                    //     <ClientProfileView /> Dashboard
                    //     <div style={{ paddingTop: '20px' }}>
                    //         <ClientLogo />
                    //     </div>
                    // </Container>
                    

                    <div className="page-content">

                    <Table>
                        <Row className="row-style-1">
                            <Col>
                            <section className="col-style-1 col-border-1">

                            {/* col-style-1 */}
                            <Table style={{ textAlign: 'center' }}>
                                <Row style={{ paddingTop: '50px' }}>
                                    <Col>
                                        <section className="label-style-1">Welcome, {activeAccount.name}</section>
                                        <section className="label-style-2">Today is {dateToday}</section>
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: '60px' }}>
                                    <Col className="border-right">
                                        <section className="label-style-2">YTD Completed Requests</section>
                                        <section className="label-style-1">15</section>
                                    </Col>
                                    <Col>
                                        <section className="label-style-2">Open Items</section>
                                        <section className="label-style-1">7</section>
                                    </Col>
                                </Row>
                            </Table>

                            </section>
                            </Col>

                            <Col>
                            <section className="col-style-2 col-border-1">

                                {/* col-style-2 */}
                                <LineChart chartData={Data} />                                

                            </section>
                            </Col>

                        </Row>

                        <Row className="row-style-2">
                            <Col className="col-style-3">

                            {/* <div className="col-style-3"> */}
                                        {/* col-style-3 */}
                                        
                                        <span className="label-style-1" style={{ textAlign: 'left' }}>Recent Requests</span>

                                        {/* <Table className="x-table-head">
                                        <thead>
                                            <tr>
                                            <th className="td-15pct fz-gray-3">
                                                <span>Request Type</span>
                                            </th>
                                            <th className="td-15pct fz-gray-3">
                                            <span>Subject</span>
                                            </th>
                                            <th className="td-15pct fz-gray-3">
                                            <span>Due Date</span>
                                            </th>
                                            <th className="td-10pct fz-gray-3">
                                            <span>Status</span>
                                            </th>
                                            <th className="td-10pct fz-gray-3">
                                            <span>Submitted By</span>
                                            </th>
                                            <th className="td-20pct fz-gray-3">Last Note</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ borderWidth: '0px' }}>
                                        </tbody>
                                        </Table>

                                        <ListGroup>
                                            <div className="message-scroll-area-2" style={{ padding: '10px', textAlign: 'center' }}>
                                            <Table> 
                                            {messageDisplay}
                                            </Table>
                                            </div>
                                        </ListGroup> */}

                                    <RecentRequests />


                            {/* </div> */}
                                                       
                            </Col>
                        </Row>                        
                    </Table>

                    </div> 
                   

                    :
                    null
                }
            </AuthenticatedTemplate>
        </>
    )
}

export const MessageItem = (props) => {

    const handleClickItem = (id) => {
        console.log('handleClickItem', id);
        // navigate('/service-request/'+id);
    }

    const viewTemplate = (
        // <div key={props.messageId} style={{ paddingTop: '5px', marginBottom: '-10px' }}>
        //     <div>
        //         <span className="message-from">{props.messageFrom}</span>
        //         <span className="message-time">{props.messageTime}</span>
        //     </div>
        //     <div className="message-text">
        //         {props.messageText}
        //     </div>
        // </div>

        <tbody style={{ width: '100%', borderWidth: '0px', borderColor: '#ffffff' }}>
          <tr className=' list-item list-item-padding no-borders'>
            <td className='td-10pct td-center-v td-left-h'>{props.messageFrom || ''}</td>
            <td className='td-15pct td-center-v td-left-h'>{props.messageFrom || ''}</td>
            <td className='td-15pct td-center-v td-left-h'>{props.messageFrom || ''}</td>
            <td className='td-5pct td-center-v td-left-h'>{props.messageFrom || ''}</td>
            <td className='td-10pct td-center-v td-left-h'>{props.messageFrom || ''}</td>
            <td className='td-10pct td-center-v td-left-h'>{props.messageFrom || ''}</td>
            {/* <td className='td-20pct td-center-v td-center-h pos-tweak-2'>
              <span className={props.messageFrom}></span>
            </td>
            <td className='td-20pct td-center-v td-center-h pos-tweak-2'>
              <span className={props.messageFrom}></span>
            </td> */}
          </tr>
        </tbody>
        
    );

    return <ListGroup.Item key={props.messageId} onClick={() => handleClickItem(props.messageId)}
                           style={{ minHeight: '40px', marginTop: '4px', marginBottom: '-4px' }}>
                {viewTemplate}
            </ListGroup.Item>;
}