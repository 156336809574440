import React, { useContext } from "react";
import { DataContext } from "../App";

import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { ClientProfileView } from '../components/ClientProfileView';

import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PushNotification } from '../components/PushNotification';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';

export const Settings = (props) => {
// export function Settings() {

  const { data, setData } = useContext(DataContext);

  const { instance, inProgress } = useMsal();
  let activeAccount;

  if (instance) {
      activeAccount = instance.getActiveAccount();
      console.log("activeAccount", activeAccount);
  }


  const handleClick = () => {
    const updatedData = 'Updated Data';
    setData(updatedData);

    // toast("Wow so easy!");

    const options = {
      // onOpen: props => console.log(props.foo),
      // onClose: props => console.log(props.foo),
      // closeButton: <CloseIcon />
      type: toast.info,
      className: "fz-green-bg-5",
      transition: Slide,
      hideProgressBar: true,
      autoClose: 9000,
      pauseOnHover: true,
    };
  
    let msgLine1 = "Finzen Message";
    let msgLine2 = "John H Smith replied to";
    let msgLine3 = "Monthly report recalc from performance team";

    // const notify = () => toast("Wow so easy!");
    toast.warn(<PushNotification msgLine1={msgLine1} 
      msgLine2={msgLine2} msgLine3={msgLine3} />, options);

  };

  return (
    <div className="page-content">
      
      {/* {data} */}

      <div style={{ fontSize: '1.5em', fontWeight: 'bold' }}>Settings</div>
      <span className="fz-font-medium fz-gray-2">Finzen Version 24.06.27</span>
      <br /><br />

      {/* <button onClick={handleClick}>Update Parent State</button> */}

      <div>
        <span style={{ fontWeight: 'bold' }}>Client:</span> <ClientProfileView />
      </div>
      <div>
        <span style={{ fontWeight: 'bold' }}>User Name:</span> {activeAccount && activeAccount.username ? activeAccount.username : 'Unknown'}
      </div>

    </div>
  );

}
