import React, { useState, useRef, useEffect } from "react";
import {Routes, Route, useNavigate} from 'react-router-dom';
import { useMsal } from "@azure/msal-react";
import { nanoid } from "nanoid";

import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { protectedResources } from "../authConfig";

import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import { Input, Table, Row, Col } from 'reactstrap';
import date from 'date-and-time'; 

function usePrevious(value) {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
}

export const ServiceRequestsView = (props) => {
    const { instance } = useMsal();
    const account = instance.getActiveAccount();

    const { error, execute } = useFetchWithMsal({
        scopes: protectedResources.apiClientRequests.scopes.write
    });

    const [requests, setRequests] = useState(props.requestData);


    let activeAccount;
    let fs_list = [];

    if (instance) {
        activeAccount = instance.getActiveAccount();
        let ext_fs = activeAccount.idTokenClaims.extension_fs;

        if (ext_fs !== null)
            fs_list = ext_fs.split(' ');

        console.log("fs_list", fs_list);
    }

    const requestList = requests.length > 0 ? (
        requests.map(request => (
            <RequestItem
                id={request.ClientRequestId}
                ClientName={request.ClientName}
                RequestBy={request.RequestBy}
                Category={request.Category}
                TaskType={request.TaskType}
                Subject={request.Subject}
                RequestStatus={request.Status}
                RequestDate={ date.format(new Date(request.RequestDate), 'M/D/YYYY h:mm A', false) }
                key={request.ClientRequestId}
            />
        ))
    ) : (
        <div className='td-center-h'>No data available</div>
    );

    const listHeadingRef = useRef(null);
    const prevListLength = usePrevious(requests.length);

    useEffect(() => {
        if (requests.length - prevListLength === -1) {
            listHeadingRef.current.focus();
        }
    }, [requests.length, prevListLength]);

    if (error) {
        return <div>Error: {error.message}</div>;
    }
    
    return (
        <div>
            {/* <h2 id="list-heading" tabIndex="-1" ref={listHeadingRef}></h2> */}
            <ListGroup>
                {requestList}
            </ListGroup>
        </div>
    );
}


export const RequestItem = (props) => {

    const navigate = useNavigate();

    const handleClickItem = (id) => {
        console.log('handleClickItem', id);

        // this.props.navigation('/todolist/'+categoryTypeId);
        navigate('/service-request/'+id);
    }

    let statusColor = '#bababa';

    switch(props.RequestStatus) {
        case "New Request":
            statusColor = "#ffc107";
            break;
        case "Assigned":
            statusColor = "#7da7d1";
            break;
        case "In Progress":
            statusColor = "#b5eba2";
            break;
        case "Complete":
            statusColor = "#3a9c6c";
            break;
    }

    const viewTemplate = (
        <div onClick={() => handleClickItem(props.id)}
             key={props.id} style={{ paddingTop: '5px', marginBottom: '-20px' }}>
                
            <Table className="request-props">
                <tr>
                    <td style={{ width: '60%' }}>
                        <span className="list-label" style={{ fontWeight: 'bold' }}>
                            {props.ClientName} | {props.Category} | {props.TaskType} 
                        </span>
                    </td>
                    <td style={{ width: '10%' }}>
                        &nbsp;
                    </td>
                    <td style={{ width: '30%' }}>
                        <span className="list-label status-assigned" style={{ backgroundColor: statusColor }}>
                            {props.RequestStatus}
                        </span>
                    </td>                            
                </tr>
                <tr>
                    <td>
                        <span className="list-label">
                            {props.Subject}
                        </span>
                    </td>
                    <td>
                        <span className="list-label" />
                    </td>
                    <td className="td-right-h">
                        <span className="list-label" />
                    </td>                            
                </tr>
                <tr>
                    <td>
                        <span className="list-label">
                            {props.RequestBy}
                        </span>
                    </td>
                    <td>
                        <span className="list-label" />
                    </td>
                    <td className="td-right-h">
                        <span className="list-label">
                            {props.RequestDate}
                        </span>
                    </td>                            
                </tr>
            </Table>
        </div>
    );

    return <ListGroup.Item>{viewTemplate}</ListGroup.Item>;
}