import { useEffect, useState } from 'react';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';

import { loginRequest, protectedResources } from "../authConfig";
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { ClientListView } from '../components/ClientListView';

const ClientListContent = () => {
    const { error, execute } = useFetchWithMsal({
        scopes: protectedResources.apiClientList.scopes.read,
    });

    const [clientListData, setClientListData] = useState(null);

    useEffect(() => {
        if (!clientListData) {
            execute("GET", protectedResources.apiClientList.endpoint).then((response) => {
                setClientListData(response);
                console.log(response);
            });
        }
    }, [execute, clientListData])

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return <>{clientListData ? <ClientListView clientListData={clientListData} /> : null}</>;
};


const ErrorComponent = ({ error }) => {
    return (
      <div>
        <h1>Authentication Error</h1>
        <p>Error code: {error.errorCode}</p>
        <p>Error description: {error.errorMessage}</p>
        <button onClick={() => { /* handle retry or redirect to login */} }>Retry</button>
      </div>
    );
  };


/**
 * The `MsalAuthenticationTemplate` component will render its children if a user is authenticated
 * or attempt to sign a user in. Just provide it with the interaction type you would like to use
 * (redirect or popup) and optionally a request object to be passed to the login API, a component to display while
 * authentication is in progress or a component to display if an error occurs. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const ClientList = () => {
    const authRequest = {
        ...loginRequest,
    };

    return (
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest}
            errorComponent={<ErrorComponent />}
            >
            <ClientListContent />
        </MsalAuthenticationTemplate>
    );
};
