import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


const CLIENT_ID = '865183107908-d59gjf3grqpuuqmi40soshlseei3n5ls.apps.googleusercontent.com';
const SCOPES = 'https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/drive.file';
// const REDIRECT_URI = 'http://localhost:3002/oauth2callback';
// const REDIRECT_URI = 'https://proud-smoke-0b3ff640f-preview.eastus2.4.azurestaticapps.net/oauth2callback';
const REDIRECT_URI = 'https://finzen.work/oauth2callback';


const Auth = () => {
  const history = useNavigate();

  useEffect(() => {
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=${SCOPES}&access_type=offline`;

    // Redirect to Google OAuth2 consent screen
    window.location.href = authUrl;
  }, [history]);

  return <div>Redirecting to Google OAuth2...</div>;
};

export default Auth;
