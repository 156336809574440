import React, { Component } from "react";

import { Navigate } from "react-router-dom";
// import UserService from "../services/user.service";
// import EventBus from "../common/EventBus";

import { Link } from "react-router-dom";


export class AdminTools extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAdminContent: false,
      content: "",
      redirect: null,
      currentUser: { UserName: "" },
      adminCounters: [],
    };

    this.handleListItemClick = this.handleListItemClick.bind(this);

  }

  componentDidMount() {
    document.body.style.backgroundImage = "";
    document.body.style.backgroundColor = "#eeeeee";


    // UserService.getAdminDashboard().then(

    //   response => {

    //   UserService.getAdminCounters()
    //   .then(
    //     response => {
    //       this.setState({
    //         adminCounters: response.data,
    //         isLoading: false
    //       });
  
    //     // console.log(this.state.adminCounters);
  
    //     },
    //     error => {
    //       this.setState({
    //         content:
    //           (error.response &&
    //             error.response.data &&
    //             error.response.data.message) ||
    //           error.message ||
    //           error.toString()
    //       });
  
    //       if (error.response && error.response.status === 403) {
    //         EventBus.dispatch("logout");
    //       }
    //     }
    //   );  

    //   /*
    //   response => {
    //     this.setState({
    //       content: response.data
    //     });
    //   */

    //   },
    //   error => {
    //     this.setState({
    //       content:
    //         (error.response &&
    //           error.response.data &&
    //           error.response.data.message) ||
    //         error.message ||
    //         error.toString()
    //     });

    //     if (error.response && error.response.status === 403) {
    //       EventBus.dispatch("logout");
    //     }
    //   }
    // );



  }


  async handleListItemClick(event) {
    
    console.log("handleListItemClick...");

  }

  
  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />
    }

    const {adminCounters} = this.state;

    const personCount = adminCounters.find((el, i) => el.RecordType === "Person");
    const teamCount = adminCounters.find((el, i) => el.RecordType === "Team");
    const clientCount = adminCounters.find((el, i) => el.RecordType === "Client");
    const userCount = adminCounters.find((el, i) => el.RecordType === "User");
    const taskCount = adminCounters.find((el, i) => el.RecordType === "Task");

    let ViewPropertyList;

    ViewPropertyList =
    <>
    <ul className="list-group">

      <li className="list-group-item d-flex justify-content-between align-items-center nav-item list-item-main">
        <span className="icon-client">&nbsp; &nbsp; &nbsp;</span>
        <Link to={"/clientlist"} className="nav-link">
            Client List
        </Link>
        <span className="badge badge-primary badge-pill">{clientCount? clientCount.Total : 0}</span>
      </li>

      <li className="list-group-item d-flex justify-content-between align-items-center nav-item list-item-main">
        <span className="icon-team">&nbsp; &nbsp; &nbsp;</span>
        <Link to={"/team-list"} className="nav-link">
            Team List
        </Link>
        <span className="badge badge-primary badge-pill">{teamCount? teamCount.Total : 0}</span>
      </li>

      <li className="list-group-item d-flex justify-content-between align-items-center nav-item list-item-main">
        <span className="icon-person">&nbsp; &nbsp; &nbsp;</span>
        <Link to={"/person_list"} className="nav-link">
            Person List
        </Link>
        <span className="badge badge-primary badge-pill">{personCount? personCount.Total : 0}</span>
      </li>

      {/* <li className="list-group-item d-flex justify-content-between align-items-center nav-item list-item-main"
          onClick={() => this.handleListItemClick()}> */}

      <li className="list-group-item d-flex justify-content-between align-items-center nav-item list-item-main">
        <span className="icon-user">&nbsp; &nbsp; &nbsp;</span>
        <Link to={"/user_list"} className="nav-link">
          Finzen Users
        </Link>
        <span className="badge badge-primary badge-pill">{userCount? userCount.Total : 0}</span>
      </li>     

      {/* <li className="list-group-item d-flex justify-content-between align-items-center nav-item list-item-main">
        <span className="icon-person">&nbsp; &nbsp; &nbsp;</span>
        <Link to={"/person_list"} className="nav-link">
            Calendar Maintenance
        </Link>
        <span>&nbsp;</span>
      </li> */}

      <li className="list-group-item d-flex justify-content-between align-items-center nav-item list-item-main">
        <span className="icon-task">&nbsp; &nbsp; &nbsp;</span>
        <Link to={"/task_template_category"} className="nav-link">
          Task Templates
        </Link>
        <span className="badge badge-primary badge-pill">{taskCount? taskCount.Total : 0}</span>
      </li>

    </ul>    
    </>;      

    return (
      <div>
        {/* <h3>{this.state.content}</h3> */}
        <div className="control-padding"><h4>Admin Dashboard</h4></div>
        {ViewPropertyList}
      </div>
    );
  }
}
