import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';


const CLIENT_ID = '865183107908-d59gjf3grqpuuqmi40soshlseei3n5ls.apps.googleusercontent.com';
const CLIENT_SECRET = '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';
// const REDIRECT_URI = 'http://localhost:3002/oauth2callback';
// const REDIRECT_URI = 'https://proud-smoke-0b3ff640f-preview.eastus2.4.azurestaticapps.net/oauth2callback';
const REDIRECT_URI = 'https://finzen.work/oauth2callback';


const Redirect = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTokens = async (code) => {
      const response = await fetch('https://oauth2.googleapis.com/token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          code: code,
          client_id: CLIENT_ID,
          client_secret: CLIENT_SECRET,
          redirect_uri: REDIRECT_URI,
          grant_type: 'authorization_code',
        }),
      });

      const data = await response.json();
      console.log('Access Token:', data.access_token);
      console.log('Refresh Token:', data.refresh_token);
      // Store tokens and use them to access Google APIs

      if (data) {
        try {
          let refreshToken = 'error getting token';
          if (data.refresh_token !== null || data.refresh_token !== undefined) {
            refreshToken = data.refresh_token.replace('1//', '1||');
          }

          navigate('/module601/'+refreshToken);  

        } catch (error) {
          console.error('1) Registration failed with ${error}');
        }
        }

    };

    const query = new URLSearchParams(location.search);
    const code = query.get('code');

    if (code) {
      fetchTokens(code);
    }
  }, [location]);

  return <div>Handling OAuth2 Redirect...</div>;
};

export default Redirect;
