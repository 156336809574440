import React, { useState, useRef, useEffect } from "react";
import {Routes, Route, useNavigate, useParams} from 'react-router-dom';
import { useMsal } from "@azure/msal-react";
import { nanoid } from "nanoid";
import ListGroup from "react-bootstrap/ListGroup";

import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { protectedResources } from "../authConfig";

import Dropdown from 'react-bootstrap/Dropdown';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import { Button, InputGroup } from "react-bootstrap";

import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { CircularProgress } from '@mui/material';

import { FormGroup, Input, Label } from 'reactstrap';
import { Container, Row, Col } from 'reactstrap';

import DatePicker from "react-datepicker";
import date from 'date-and-time'; 
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';

export const ServiceRequestView = (props) => {

    const { instance, inProgress } = useMsal();
    let activeAccount;
    let fs_list = [];
  
    if (instance) {
        activeAccount = instance.getActiveAccount();
        let ext_fs = activeAccount.idTokenClaims.extension_fs;
  
        if (ext_fs !== null)
            fs_list = ext_fs.split(' ');
    }

    const { error, execute } = useFetchWithMsal({
        scopes: protectedResources.apiClientRequest.scopes.write
    });

    const navigate = useNavigate();
    const { id } = useParams();
    const [clientRequest, setClientRequest] = useState(null);
    const [messageList, setMessageList] = useState([]);
    const [editDisabled, setEditDisabled] = useState(true);
    const [agentEnabled, setAgentEnabled] = useState(false);
    const [statusLabel, setStatusLabel] = useState('');
    const [statusColor, setStatusColor] = useState('#bababa');

    const [requestCategory, setRequestCategory] = useState('');
    const [requestType, setRequestType] = useState('');
    const [requestSubject, setRequestSubject] = useState('');
    const [requestInstructions, setRequestInstructions] = useState('');
    const [requestPriority, setRequestPriority] = useState('');
    const [requestAssignedTo, setRequestAssignedTo] = useState('');
    const [requestAssignedDate, setRequestAssignedDate] = useState(null);
    const [requestResolvedBy, setRequestResolvedBy] = useState('');
    const [requestResolvedDate, setRequestResolvedDate] = useState(null);
    const [selectedDueDate, setSelectedDueDate] = useState(null);
    const [requestStatus, setRequestStatus] = useState('');

    const [categoryList, setCategoryList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [typeList, setTypeList] = useState([]);
    const [selectedType, setSelectedType] = useState(null);
    const [priorityList, setPriorityList] = useState([]);
    const [selectedPriority, setSelectedPriority] = useState(null);
    const [statusList, setStatusList] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [requestedBy, setRequestedBy] = useState(null);
    const [clientName, setClientName] = useState(null);
    const [teamList, setTeamList] = useState([]);
    const [selectedAssignedTo, setSelectedAssignedTo] = useState(null);
    const [selectedResolvedBy, setSelectedResolvedBy] = useState(null);

    const [messageDisplay, setMessageDisplay] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    let messages = [];

    useEffect(() => {
        setCategoryList([
            { label: "Analytics", value: 1001 },
            { label: "Reporting", value: 1002 },
            { label: "Trading", value: 1003 },
            { label: "Accounting", value: 1004 },
            { label: "Data Aggregation", value: 1005 },
        ]);

        setTypeList([
            { label: "Performance Reporting", value: 1101 },
            { label: "Custom Client Reporting", value: 1102 },
            { label: "Rebalancing", value: 1103 },
            { label: "Fund Administration", value: 1104 },
            { label: "Native Custodial Feed Builds", value: 1105 },
        ]);

        setPriorityList([
            { label: "Urgent", value: 1 },
            { label: "High", value: 2 },
            { label: "Regular", value: 3 },
            { label: "Low", value: 4 },
        ]);

        setStatusList([
            { label: "New Request", value: 500 },
            { label: "Assigned", value: 501 },
            { label: "In Progress", value: 502 },
            { label: "Complete", value: 510 },
        ]);

        setTeamList([
            { label: "Ghrewati, Samim", value: '57e11066-b889-47bd-8387-871956664457' },
            { label: "Kotran, Josh", value: '08215e18-9021-4c9b-9753-272822b4c46b' },
        ]);

        if (id) {
            console.log("Load data for requestId: " + id);

            execute("GET", protectedResources.apiClientRequest.endpoint+id)
                .then((response) => {
                    setClientRequest(response.request_detail);
                    setMessageList(response.messages);
                    setIsLoading(false);
                });            
        } else {
            console.log("no requestId  -->  new request");
        }


    }, [execute])
    
    useEffect(() => {
        console.log('isLoading', isLoading);
    }, [isLoading])

    useEffect(() => {
        console.log('agentEnabled', agentEnabled);
    }, [agentEnabled])

    useEffect(() => {
        console.log('editDisabled', editDisabled);
    }, [editDisabled])

    useEffect(() => {
        let storedAssignedTo = '';
        let storedResolvedBy = '';

        if (fs_list.includes('110') || fs_list.includes('111')) {
            setAgentEnabled(true);
        }

        if (clientRequest) {  // Existing request
            console.log('clientRequest', clientRequest);
            console.log('messageList', messageList);

            setEditDisabled(true);

            clientRequest.DueDate = clientRequest.DueDate.replace('Z', '');
            let storedDueDate = date.format(new Date(clientRequest.DueDate), 'YYYY-MM-DD 00:00:00', false);

            let storedStatus = statusList.find(lookup => lookup.value === clientRequest.RequestStatusTypeId);
            let storedCategory = categoryList.find(lookup => lookup.value === clientRequest.CategoryTypeId);
            let storedType = typeList.find(lookup => lookup.value === clientRequest.ClientRequestType);
            let storedPriority = priorityList.find(lookup => lookup.value === clientRequest.PriorityTypeId);

            if (clientRequest.AssignedTo !== null) {
                storedAssignedTo = teamList.find(lookup => lookup.value === clientRequest.AssignedTo);
            }

            if (clientRequest.ResolvedBy !== null) {
                storedResolvedBy = teamList.find(lookup => lookup.value === clientRequest.ResolvedBy);
            }

            setRequestStatus(clientRequest.RequestStatusTypeId);
            setRequestCategory(clientRequest.CategoryTypeId);
            setRequestType(clientRequest.ClientRequestType);
            setRequestPriority(clientRequest.PriorityTypeId);
            setRequestAssignedTo(clientRequest.AssignedTo);
            setRequestResolvedBy(clientRequest.ResolvedBy);

            if (clientRequest.AssignedDate !== null)
                setRequestAssignedDate(date.format(new Date(clientRequest.AssignedDate), 'MM/DD/YYYY', false));

            if (clientRequest.ResolvedDate !== null)
                setRequestResolvedDate(date.format(new Date(clientRequest.ResolvedDate), 'MM/DD/YYYY', false));

            setSelectedStatus( {label: storedStatus.label, value: storedStatus.value} );
            setSelectedCategory( {label: storedCategory.label, value: storedCategory.value} );
            setSelectedType( {label: storedType.label, value: storedType.value} );
            setRequestSubject(clientRequest.Subject);
            setRequestInstructions(clientRequest.Instructions);
            setSelectedPriority( {label: storedPriority.label, value: storedPriority.value} );
            setSelectedAssignedTo( {label: storedAssignedTo.label, value: storedAssignedTo.value} );
            setSelectedResolvedBy( {label: storedResolvedBy.label, value: storedResolvedBy.value} );
            setSelectedDueDate(storedDueDate);
            setRequestedBy(clientRequest.RequestBy);
            setClientName(clientRequest.ClientName);

            updateStatusColor(clientRequest.RequestStatusTypeId);

            setMessageDisplay(messages);

        } else {  // New request
            setRequestedBy(activeAccount.name);
            setEditDisabled(false);
            updateStatusColor(500);
        }
    }, [execute, clientRequest])


    useEffect(() => {

        messages = messageList.length > 0 ? (
            messageList.map(message => (
                <MessageItem
                    messageId={message.ClientMessageId}
                    messageText={message.MessageBody}
                    messageFrom={message.FirstName + ' ' + message.LastName}
                    messageTime={ date.format(new Date(message.TimeStamp), 'M/D/YY h:mm A', false) }
                />
            ))
        ) : (
            <></>
        );

        setMessageDisplay(messages);

    }, [execute, messageList])


    if (error) {
        return <div>Error: {error.message}</div>;
    }
    
    // --------------------------------------------------

    const handleInputSubject = (e) => {
        setRequestSubject(e.target.value);
    }
    const handleInputInstructions = (e) => {
        setRequestInstructions(e.target.value);
    }

    // --------------------------------------------------

    const setDueDate = date => {
        console.log('setDueDate >>', date);
        setSelectedDueDate(date);
    };

    const handleSelectStatus = (selectedStatus) => {
        setSelectedStatus(selectedStatus);
        setRequestStatus(selectedStatus.value);
        updateStatusColor(selectedStatus.value);
        if (selectedStatus !== null) {
            console.log('selectedStatus', selectedStatus);
            if (selectedStatus.value === 500) {
                setSelectedAssignedTo(null);
                setRequestAssignedDate(null);
                setSelectedResolvedBy(null);
                setRequestResolvedDate(null);
            }
        }
    }

    const handleSelectCategory = (selectedCategory) => {
        setSelectedCategory(selectedCategory);
        setRequestCategory(selectedCategory.value);
        if (selectedCategory !== null) {
            console.log('selectedCategory', selectedCategory);
        }
    }

    const handleSelectType = (selectedType) => {
        setSelectedType(selectedType);
        setRequestType(selectedType.value);
        if (selectedType !== null) {
            console.log('selectedType', selectedType);
        }
    }

    const handleSelectPriority = (selectedPriority) => {
        setSelectedPriority(selectedPriority);
        setRequestPriority(selectedPriority.value)
        if (selectedPriority !== null) {
            console.log('selectedPriority', selectedPriority);
        }
    }

    const handleSelectAssignedTo = (selectedAssignedTo) => {
        setSelectedAssignedTo(selectedAssignedTo);
        setRequestAssignedTo(selectedAssignedTo.value)
        setRequestAssignedDate(date.format(new Date(), 'MM/DD/YYYY', false));  //****
        if (selectedAssignedTo !== null) {
            console.log('selectedAssignedTo', selectedAssignedTo);
        }
    }

    const handleSelectResolvedBy = (selectedResolvedBy) => {
        setSelectedResolvedBy(selectedResolvedBy);
        setRequestResolvedBy(selectedResolvedBy.value)
        setRequestResolvedDate(date.format(new Date(), 'MM/DD/YYYY', false));  //****
        if (selectedResolvedBy !== null) {
            console.log('selectedResolvedBy', selectedResolvedBy);
        }
    }

    const updateStatusColor = (requestStatusTypeId) => {
        switch(requestStatusTypeId) {
            case 500:
                setStatusLabel("New Request")
                setStatusColor("#ffc107");
                break;
            case 501:
                setStatusLabel("Assigned")
                setStatusColor("#7da7d1");
                break;
            case 502:
                setStatusLabel("In Progress")
                setStatusColor("#b5eba2");
                break;
            case 510:
                setStatusLabel("Complete")
                setStatusColor("#3a9c6c");
                break;
        }
    }


    const handleSubmitRequest = (e) => {

        e.preventDefault();
        console.log('handleSubmitRequest');

        let dueDate = date.format(new Date(selectedDueDate), 'YYYY-MM-DD 00:00:00', true);
        
        let assignedDate = null;
        if (requestAssignedDate)
            assignedDate = date.format(new Date(requestAssignedDate), 'YYYY-MM-DD HH:mm:ss', true);

        let resolvedDate = null;
        if (requestResolvedDate)
            resolvedDate = date.format(new Date(requestResolvedDate), 'YYYY-MM-DD HH:mm:ss', true);

        let requestId = 0;
        let statusId = 500;
        let assignedToId = null;
        let resolvedToId = null;

        if (clientRequest) {
            requestId = clientRequest.ClientRequestId;
            statusId = selectedStatus.value;
            if (selectedAssignedTo)
                assignedToId = selectedAssignedTo.value;
            if (selectedResolvedBy)
                resolvedToId = selectedResolvedBy.value;
        }

        const sendParams = {
            ClientRequestId: requestId,
            Category: selectedCategory.value,
            Type: selectedType.value,
            Subject: requestSubject,
            Instructions: requestInstructions,
            Priority: selectedPriority.value,
            AssignedTo: assignedToId,
            AssignedDate: assignedDate,
            ResolvedBy: resolvedToId,
            ResolvedDate: resolvedDate,
            DueDate: dueDate,
            RequestBy: activeAccount.localAccountId,
            RequestStatusTypeId: statusId
        };

        console.log('sendParams', sendParams);

        execute("POST", protectedResources.apiNewClientRequest.endpoint, sendParams)
            .then((response) => {
                console.log(response);

                handlePageBack();
            }
        );

        if (error) {
            return <div>Error: {error.message}</div>;
        }
    
        return <>done</>;

    };


    const handlePageBack = () => {
        navigate(-1);
    }
    

    const NewMessageDialog = () => {
        const [isOpen, setIsOpen] = useState(false);
        const [newMessageText, setNewMessageText] = useState('');
        const [isBlank, setIsBlank] = useState();

        const handleOpen = () => {
          setIsOpen(true);
        };
      
        const handleClose = () => {
          setIsOpen(false);
        };
        
        const handleMessageEntry = (event) => {
            setNewMessageText(event.target.value);
            if (event.target.value !== '')
                setIsBlank(false);
        };

        const handleSubmitMessage = (event) => {
          event.preventDefault();
          if (newMessageText.trim() === '') {
            setIsBlank(true);
          } else {
            console.log("newMessageText", newMessageText);

            let requestId = 0;

            if (clientRequest) {
                requestId = clientRequest.ClientRequestId;
            }

            const sendParams = {
                ClientRequestId: requestId,
                MessageBody: newMessageText,
            };
    
            // Add message
            execute("POST", protectedResources.apiNewRequestMessage.endpoint, sendParams)
                .then((response) => {
                    // Refresh message list
                    console.log(response);
                    setMessageList(response.messages);
                }
            );

            handleClose();
          }
        };
      
        return (
        <>
            <AddCircleIcon className="add-message" onClick={handleOpen} />
            {isOpen && (
            <div className="popup-dialog">
                <div className="popup-content">
                <h4>New Message</h4>
                <form onSubmit={handleSubmitMessage}>
                    <Row>
                        <Col style={{ width: '400px', paddingTop: '10px' }}>
                        <FormGroup>
                            <Form.Control as="textarea" rows={4} value={newMessageText}
                                          autoFocus={true} onChange={handleMessageEntry} 
                                          tabIndex="9"
                                          style={{ backgroundColor:   
                                            isBlank ? '#FFC0CB' : '#ffffff'
                                          }}  
                            />
                        </FormGroup>     
                        </Col>
                    </Row>
                    <Button type="submit" variant="success" tabIndex="11" style={{ float: 'right' }}>Save</Button>
                </form>
                <Button variant="secondary" style={{ float: 'right', marginRight: '10px' }} 
                        tabIndex="10" onClick={handleClose}>Cancel</Button>
                </div>
            </div>
            )}
        </>
        );
      };     


    return (

        <div className="data-area-div">

            {/* <span id="fileCounter"></span> */}
            
            <Table borderless size="sm" className="svc-req-page">

            <Row>
                <Col className='col-12'>
                    <span>
                        <ArrowCircleLeftOutlinedIcon className="back-button" onClick={handlePageBack} />
                        <span style={{paddingLeft: '10px', fontSize: '1.5em', fontWeight: 'bold', verticalAlign: 'middle'}}>
                            Service Request
                        </span>
                    </span>
                    <div style={{ marginLeft: '45px', fontSize: 'large' }}>
                        {editDisabled ? <></>
                          : <span>Send a request to your service team and track its status</span>
                        }
                    </div>
                </Col>
            </Row>

            <Row>
                <Col className='col-12'>

                <Row>
                    <Col className='col-8'>

                        <Container>

                            <Form onSubmit={handleSubmitRequest}>

                            <Container className="form-scroll-area">

                                { clientRequest ? (

                                <Row className='form-row'>

                                    <Col className='col-3'>
                                        <Label className='fz-gray-2' style={{ float: 'right', fontSize: 'large' }}>Status</Label>
                                    </Col>
                                    <Col className='col-4'>
                                        {agentEnabled ? 
                                        <Form.Group>
                                            <InputGroup className="mb-7" hasValidation>

                                                <Form.Control
                                                    style={{ height: '0px', paddingTop: '3px', marginLeft: '5px', marginRight: '5px', zIndex: '0' }}
                                                    required
                                                    type="text"
                                                    id="category-input"
                                                    aria-describedby="inputCategory"
                                                    autoComplete="off"
                                                    value={requestStatus}
                                                    onChange={handleSelectStatus}
                                                />

                                                <span style={{ width: '100%', marginTop: '-15px', zIndex: '15' }}>
                                                <Select 
                                                    tabIndex="2"
                                                    item="3"
                                                    maxMenuHeight={160}
                                                    value={selectedStatus}
                                                    disabled={editDisabled && !agentEnabled}
                                                    onChange={handleSelectStatus}
                                                    options={ statusList.map( opt => ({ label: opt.label, value: opt.value })) }
                                                />
                                                </span>

                                            </InputGroup>
                                            <Form.Control.Feedback type="invalid">
                                                Please select a Request Type
                                            </Form.Control.Feedback>                                        
                                        </Form.Group>
                                        : <></>}
                                    </Col>
                                    <Col className='col-4'>
                                        <div className="status-label">
                                            <span className="status-assigned" style={{ backgroundColor: statusColor }}>
                                                {statusLabel}
                                            </span>
                                        </div>
                                    </Col>
                                </Row>

                                ): <></>}


                                { agentEnabled && clientRequest ? (

                                <Row className='form-row' style={{ backgroundColor: '#FFFBEA' }}>

                                    <Col className='col-3' style={{ marginTop: '3px' }}>
                                        <Label className='fz-gray-2' style={{ float: 'right', fontSize: 'large' }}>Assigned To</Label>
                                    </Col>
                                    <Col className='col-5' style={{ marginTop: '4px' }}>
                                        <Form.Group>
                                            <InputGroup className="mb-7" hasValidation>

                                                <Form.Control
                                                    style={{ height: '0px', paddingTop: '3px', marginLeft: '5px', marginRight: '5px', zIndex: '1' }}
                                                    required
                                                    type="text"
                                                    id="category-input"
                                                    aria-describedby="inputCategory"
                                                    autoComplete="off"
                                                    value={requestAssignedTo}
                                                    onChange={handleSelectAssignedTo}
                                                />

                                                <span style={{ width: '100%', marginTop: '-15px', zIndex: '14' }}>
                                                <Select 
                                                    tabIndex="2"
                                                    item="3"
                                                    maxMenuHeight={160}
                                                    value={selectedAssignedTo}
                                                    // isDisabled={selectedStatus.value > 500}
                                                    onChange={handleSelectAssignedTo}
                                                    options={ teamList.map( opt => ({ label: opt.label, value: opt.value })) }
                                                />
                                                </span>

                                            </InputGroup>
                                            <Form.Control.Feedback type="invalid">
                                                Please select a Request Type
                                            </Form.Control.Feedback>                                        
                                        </Form.Group>
                                    </Col>
                                    <Col className='col-3'>
                                        <Form.Group>
                                            <InputGroup className="mb-7" hasValidation>
                                                <Form.Control
                                                    type="text"
                                                    id="new-subject-input"
                                                    name="text"
                                                    autoComplete="off"
                                                    value={requestAssignedDate ? requestAssignedDate : ''}
                                                    disabled="true"
                                                    style={{ textAlign: 'center' }}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                ): <></>}

                                { agentEnabled && clientRequest ? (

                                <Row className='form-row' style={{ backgroundColor: '#FFFBEA' }}>

                                    <Col className='col-3' style={{ marginTop: '3px' }}>
                                        <Label className='fz-gray-2' style={{ float: 'right', fontSize: 'large' }}>Completed By</Label>
                                    </Col>
                                    <Col className='col-5' style={{ marginTop: '4px' }}>
                                        <Form.Group>
                                            <InputGroup className="mb-7" hasValidation>

                                                <Form.Control
                                                    style={{ height: '0px', paddingTop: '3px', marginLeft: '5px', marginRight: '5px', zIndex: '2' }}
                                                    // required
                                                    type="text"
                                                    id="category-input"
                                                    aria-describedby="inputCategory"
                                                    autoComplete="off"
                                                    value={requestResolvedBy && requestResolvedDate}
                                                    onChange={handleSelectResolvedBy}
                                                />

                                                <span style={{ width: '100%', marginTop: '-15px', zIndex: '13' }}>
                                                <Select 
                                                    tabIndex="2"
                                                    item="3"
                                                    maxMenuHeight={160}
                                                    value={selectedResolvedBy}
                                                    // isDisabled={requestResolvedDate && selectedStatus.label === 'Complete' }
                                                    onChange={handleSelectResolvedBy}
                                                    options={ teamList.map( opt => ({ label: opt.label, value: opt.value })) }
                                                />
                                                </span>

                                            </InputGroup>
                                            <Form.Control.Feedback type="invalid">
                                                Please select a Request Type
                                            </Form.Control.Feedback>                                        
                                        </Form.Group>
                                    </Col>
                                    <Col className='col-3'>
                                        <Form.Group>
                                            <InputGroup className="mb-7" hasValidation>
                                                <Form.Control
                                                    type="text"
                                                    id="new-subject-input"
                                                    name="text"
                                                    autoComplete="off"
                                                    value={requestResolvedDate ? requestResolvedDate : ''}
                                                    disabled="true"
                                                    style={{ textAlign: 'center' }}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                ): <></>}

                                {agentEnabled && clientName ? (

                                <Row className='form-row'>
                                    <Col className='col-3'>
                                        <Label className='fz-gray-2' style={{ float: 'right', paddingTop: '5px', fontSize: 'large' }}>Client</Label>
                                    </Col>
                                    <Col className='col-8'>
                                        <Form.Group>
                                            <InputGroup className="mb-7" hasValidation>
                                                <Form.Control
                                                    tabIndex="3"
                                                    type="text"
                                                    id="new-subject-input"
                                                    name="text"
                                                    autoComplete="off"
                                                    value={clientName}
                                                    disabled="true"
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                ): <></>}

                                <Row className='form-row'>
                                    <Col className='col-3'>
                                        <Label className='fz-gray-2' style={{ float: 'right', fontSize: 'large' }}>Category</Label>
                                    </Col>
                                    <Col className='col-8'>
                                        <Form.Group>
                                            <InputGroup className="mb-7" hasValidation>

                                                <Form.Control
                                                    style={{ height: '0px', paddingTop: '3px', marginLeft: '5px', marginRight: '5px', zIndex: '0' }}
                                                    required
                                                    type="text"
                                                    id="category-input"
                                                    aria-describedby="inputCategory"
                                                    autoComplete="off"
                                                    value={requestCategory}
                                                    onChange={handleSelectCategory}
                                                />

                                                <span style={{ width: '100%', marginTop: '-15px', zIndex: '10' }}>
                                                <Select 
                                                    tabIndex="1" autoFocus
                                                    value={selectedCategory}
                                                    isDisabled={editDisabled}
                                                    onChange={handleSelectCategory}
                                                    options={ categoryList.map( opt => ({ label: opt.label, value: opt.value })) }
                                                />
                                                </span>
                                                <Form.Control.Feedback type="invalid">
                                                    Please select a Request Category
                                                </Form.Control.Feedback>                                        
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className='form-row'>
                                    <Col className='col-3'>
                                        <Label className='fz-gray-2' style={{ float: 'right', fontSize: 'large' }}>Type</Label>
                                    </Col>
                                    <Col className='col-8'>
                                        <Form.Group>
                                            <InputGroup className="mb-7" hasValidation>

                                                <Form.Control
                                                    style={{ height: '0px', paddingTop: '3px', marginLeft: '5px', marginRight: '5px', zIndex: '0' }}
                                                    required
                                                    type="text"
                                                    id="category-input"
                                                    aria-describedby="inputCategory"
                                                    autoComplete="off"
                                                    value={requestType}
                                                    onChange={handleSelectType}
                                                />

                                                <span style={{ width: '100%', marginTop: '-15px', zIndex: '2' }}>
                                                <Select 
                                                    tabIndex="2"
                                                    item="3"
                                                    maxMenuHeight={160}
                                                    value={selectedType}
                                                    isDisabled={editDisabled}
                                                    onChange={handleSelectType}
                                                    options={ typeList.map( opt => ({ label: opt.label, value: opt.value })) }
                                                />
                                                </span>

                                            </InputGroup>
                                            <Form.Control.Feedback type="invalid">
                                                Please select a Request Type
                                            </Form.Control.Feedback>                                        
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className='form-row'>
                                    <Col className='col-3'>
                                        <Label className='fz-gray-2' style={{ float: 'right', paddingTop: '5px', fontSize: 'large' }}>Subject</Label>
                                    </Col>
                                    <Col className='col-8'>
                                        <Form.Group>
                                            <InputGroup className="mb-7" hasValidation>
                                                <Form.Control
                                                    tabIndex="3"
                                                    required
                                                    type="text"
                                                    id="new-subject-input"
                                                    name="text"
                                                    autoComplete="off"
                                                    disabled={editDisabled}
                                                    value={requestSubject}
                                                    onChange={handleInputSubject}
                                                />
                                            </InputGroup>
                                            <Form.Control.Feedback type="invalid">
                                                Please enter Request Subject
                                            </Form.Control.Feedback>                                        
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className='form-row'>
                                    <Col className='col-3'>
                                        <Label className='fz-gray-2' style={{ float: 'right', paddingTop: '3px', fontSize: 'large' }}>Instructions</Label>
                                    </Col>
                                    <Col className='col-8'>
                                        <Form.Group>
                                            <InputGroup className="mb-7" hasValidation>
                                                <Form.Control
                                                    tabIndex="4"
                                                    required
                                                    as="textarea" rows={4}
                                                    id="new-subject-input"
                                                    name="text"
                                                    autoComplete="off"
                                                    disabled={editDisabled}
                                                    value={requestInstructions}
                                                    onChange={handleInputInstructions}
                                                />
                                            </InputGroup>
                                            <Form.Control.Feedback type="invalid">
                                                Please enter Request Instructions
                                            </Form.Control.Feedback>                                        
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className='form-row'>
                                    <Col className='col-3'>
                                        <Label className='fz-gray-2' style={{ float: 'right', paddingTop: '2px', fontSize: 'large' }}>Priority</Label>
                                    </Col>
                                    <Col className='col-3'>
                                        <Form.Group>
                                            <InputGroup className="mb-7" hasValidation>
                                                <Form.Control
                                                    style={{ height: '0px', marginTop: '5px', marginLeft: '5px', marginRight: '5px', zIndex: '0' }}
                                                    required
                                                    type="text"
                                                    id="category-input"
                                                    aria-describedby="inputCategory"
                                                    autoComplete="off"
                                                    value={requestPriority}
                                                    onChange={handleSelectPriority}
                                                />

                                                <span style={{ width: '100%', marginTop: '-20px', zIndex: '10' }}>
                                                <Select 
                                                    tabIndex="5"
                                                    menuPlacement="top"
                                                    value={selectedPriority}
                                                    isDisabled={editDisabled}
                                                    onChange={handleSelectPriority}
                                                    options={ priorityList.map( opt => ({ label: opt.label, value: opt.value })) }
                                                />
                                                </span>

                                            </InputGroup>
                                            <Form.Control.Feedback type="invalid">
                                                Please select Request Priority
                                            </Form.Control.Feedback>                                        
                                        </Form.Group>
                                    </Col>

                                    <Col className='col-5'>
                                        <Form.Group style={{ float: 'right' }}>
                                            <InputGroup className="mb-7" hasValidation>

                                            <Label className='fz-gray-2' 
                                                style={{ float: 'right', paddingTop: '4px', paddingRight: '10px', fontSize: 'large' }}>
                                                Due Date
                                            </Label>

                                            <span style={{ zIndex: '10' }}>
                                            <DatePicker selected={selectedDueDate} onChange={(date) => setDueDate(date)} 
                                                        className='date-picker fz-gray-2 fz-gray-bg-7' todayButton="Today"
                                                        tabIndex="6" required
                                                        disabled={editDisabled}
                                                        // onkeydown="event.preventDefault()"
                                                        // valid={ this.state.validate.taskDueDateState === 'has-success' }
                                                        // invalid={ this.state.validate.taskDueDateState === 'has-danger' }
                                            />
                                            </span>
                                            </InputGroup>
                                            <Form.Control.Feedback type="invalid">
                                                Please select a Due Date
                                            </Form.Control.Feedback>                                        
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className='form-row'>
                                    <Col className='col-3'>
                                        <Label className='fz-gray-2' style={{ float: 'right', paddingTop: '5px', fontSize: 'large' }}>Requested By</Label>
                                    </Col>
                                    <Col className='col-8'>
                                        <Form.Group>
                                            <InputGroup className="mb-7" hasValidation>
                                                <Form.Control
                                                    type="text"
                                                    id="new-subject-input"
                                                    name="text"
                                                    autoComplete="off"
                                                    value={requestedBy}
                                                    disabled="true"
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Row>

                            </Container>

                            
                            <Row>
                                <Col className='col-2'>
                                    &nbsp;
                                </Col>
                                <Col className='col-8'>
                                    {/* { (editDisabled && agentEnabled) || (!editDisabled && agentEnabled) ?  */}

                                    { !clientRequest || agentEnabled ?  
                                        <div>
                                            {/* <span style={{ display: 'flex', float: 'left', paddingTop: '15px' }}>
                                                <Button variant='secondary' type="reset"
                                                        style={{ width: '100px' }} tabIndex="7">
                                                    Reset
                                                </Button>                  
                                            </span> */}
                                            <span style={{ display: 'flex', float: 'right', paddingTop: '15px' }}>
                                                <Button variant="success" type="submit"
                                                        disabled={editDisabled && !agentEnabled}
                                                        style={{ width: '100px' }} tabIndex="8">
                                                    Submit
                                                </Button>                  
                                            </span>
                                        </div>
                                        : 
                                        <></>
                                    }                                    

                                </Col>
                            </Row>

                            </Form>

                        </Container>
                    </Col>

                    <>

                    </>
                    {clientRequest ? 
                        <Col className='col-4'>
                            <div className="message-area message-scroll-area" style={{ padding: '10px', textAlign: 'center' }}>
                                <div style={{ paddingBottom: '10px' }}>
                                    <span className="messages-header">Messages {messageList.length > 0 ? '('+messageList.length+')' : ''}</span>
                                    <NewMessageDialog />
                                </div>
                                <ListGroup>
                                    {messageDisplay}
                                </ListGroup>
                            </div>
                        </Col>
                    : null}

                </Row>

                </Col>

                </Row>

            </Table>
           
        </div>
    );
}


export const MessageItem = (props) => {

    const handleClickItem = (id) => {
        console.log('handleClickItem', id);
        // navigate('/service-request/'+id);
    }

    const viewTemplate = (
        <div key={props.messageId} style={{ paddingTop: '5px', marginBottom: '-10px' }}>
            <div>
                <span className="message-from">{props.messageFrom}</span>
                <span className="message-time">{props.messageTime}</span>
            </div>
            <div className="message-text">
                {props.messageText}
            </div>
        </div>
    );

    return <ListGroup.Item key={props.messageId} onClick={() => handleClickItem(props.messageId)}
                           style={{ minHeight: '100px' }}>
                {viewTemplate}
            </ListGroup.Item>;
}
