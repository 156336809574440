import React from "react"; // Importing the React library
import { useState, useEffect, useRef } from 'react';
import Chart from "chart.js/auto"; // Importing the Chart.js library
import { Line } from "react-chartjs-2"; // Importing the Line component from the react-chartjs-2 library
import { Bar } from 'react-chartjs-2';

// Setting up the labels for the x-axis of the chart
const labels = ["January", "February", "March", "April", "May", "June"];

// Setting up the data for the chart, including the labels and datasets
const data = {
  labels: labels,
  datasets: [
    {
      label: "Service Requests (2024)", // Setting up the label for the dataset
      backgroundColor: "#dadada", // Setting up the background color for the dataset
      borderColor: "rgb(255, 99, 132)", // Setting up the border color for the dataset
      data: [0, 10, 5, 2, 20, 30, 45], // Setting up the data for the dataset
    },
  ],
};

// Defining the LineChart component
// const LineChart = () => {
//   return (
//     <div>
//       <Line data={data} /> // Rendering the Line component from the react-chartjs-2 library with the data passed as props
//     </div>
//   );
// };

const LineChart = () => {

  const [timeoutId, setTimeoutId] = useState(null);
  const [windowResizing, setWindowResizing] = useState(false);

  useEffect(() => {
    let timeout;
    const handleResize = () => {
      clearTimeout(timeout);

      setWindowResizing(true);

      timeout = setTimeout(() => {
        console.log('setTimeout');
        setWindowResizing(false);
      }, 200);
    }
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // const [windowSize, setWindowSize] = useState({ width: 0, height: 0 });

  // useEffect(() => {
  //   const handleResize = () => {
  //     console.log('handleResize');
  //     setWindowSize({ width: window.innerWidth, height: window.innerHeight });
  //     // Or directly trigger re-render without state update
  //     // forceUpdate(); // Not recommended for frequent re-renders
  //   };

  //   window.addEventListener('resize', handleResize);

  //   // Cleanup function to remove event listener
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []); // Empty dependency array ensures the effect runs only on




    // // Resize observer to track changes in the parent div's size
    // const resizeObserver = new ResizeObserver(() => {
    //   chartInstance.current.resize(); // Resize the chart when the parent div size changes
    // });

    // resizeObserver.observe(chartRef.current.parentElement);

    // return () => {
    //   resizeObserver.disconnect(); // Clean up the resize observer when component unmounts
    // };



  const chartRef = useRef(null); // Ref to hold the chart instance

  // Handle window resize event (optional)
  const handleResize = () => {
    console.log('handleResize');
    if (chartRef.current) {
      chartRef.current.resize(); // Trigger chart resize
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);



  return (
    <div>
      {/* <div>{windowSize.width}x{windowSize.height}</div> */}
      <Bar ref={chartRef} data={data} />
    </div>
  );
};

export default LineChart; // Exporting the LineChart component as the default export of the module
