import { useEffect, useState } from 'react';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';

import { ClientListView } from '../components/ClientListView';
import { loginRequest, protectedResources } from "../authConfig";
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import {Routes, Route, useNavigate} from 'react-router-dom';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import AppsIcon from '@mui/icons-material/GridViewOutlined';


const AppListContent = () => {

    const { instance, inProgress } = useMsal();
    let activeAccount;
    let fs_list = [];

    if (instance) {
        activeAccount = instance.getActiveAccount();
        let ext_fs = activeAccount.idTokenClaims.extension_fs;

        if (ext_fs !== null)
            fs_list = ext_fs.split(' ');

        console.log("fs_list", fs_list);
        console.log("extension_fs", activeAccount.idTokenClaims.extension_fs);
        console.log( fs_list.includes('503') );
    }

    const navigate = useNavigate();


    const openApp = (appId) => {
        console.log("openApp: "+appId);
        switch (appId) {
            case 601:
                navigate('/module601');
                return;
            case 602:
                navigate('/module602');
                return;
            default:
                return;
        }
    }


    return (
    <AuthenticatedTemplate>

    <Row xs={1} md={2} lg={3} xl={4}>

        {fs_list.includes('602') ? (
        <Col>
            <Card className="app-card" onClick={() => openApp(602)}>
                <AppsIcon className="app-icon"/>
                <Card.Body>
                    <Card.Title>Addepar Report Forwarding Service</Card.Title>
                    <Card.Text>
                    Email latest report
                    </Card.Text>
                    {/* <Button variant="secondary">Open</Button> */}
                </Card.Body>
            </Card>
        </Col> 
        ): <></>}

        {fs_list.includes('601') ? (
        <Col>
            <Card className="app-card" onClick={() => openApp(601)}>
                <AppsIcon className="app-icon"/>
                <Card.Body>
                    <Card.Title>File Transfer Service</Card.Title>
                    <Card.Text>
                    Transfer files between platforms
                    </Card.Text>
                    {/* <Button variant="secondary">Open</Button> */}
                </Card.Body>
            </Card>
        </Col> 
        ): <></>}
    
    </Row>

    </AuthenticatedTemplate>

    );

};

/**
 * The `MsalAuthenticationTemplate` component will render its children if a user is authenticated
 * or attempt to sign a user in. Just provide it with the interaction type you would like to use
 * (redirect or popup) and optionally a request object to be passed to the login API, a component to display while
 * authentication is in progress or a component to display if an error occurs. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const AppList = () => {
    const authRequest = {
        ...loginRequest,
    };

    return (
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest}>
            <AppListContent />
        </MsalAuthenticationTemplate>
    );
};
