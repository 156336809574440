import DashboardIcon from '@mui/icons-material/DashboardOutlined';
import TaskIcon from '@mui/icons-material/TaskOutlined';
import WorkflowIcon from '@mui/icons-material/PublishedWithChangesOutlined';
import ReportsIcon from '@mui/icons-material/BarChart';
import KBIcon from '@mui/icons-material/MenuBookOutlined';
import AppsIcon from '@mui/icons-material/GridViewOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import AdminIcon from '@mui/icons-material/ManageAccountsOutlined';
import PostAddIcon from '@mui/icons-material/PostAdd';

// NotificationsOffOutlined
// NotificationsOutlined

export const NavListClient = [
    {
        id: 0,
        icon: <DashboardIcon/>,
        text: "Dashboard",
        link: "home"
    },
    {
        id: 6,
        icon: <PostAddIcon/>,
        text: "Service Requests",
        link: "service-requests"
    },
    {
        id: 5,
        icon: <AppsIcon/>,
        text: "Apps",
        link: "applist"
    },
    {
        id: 8,
        icon: <KBIcon/>,
        text: "Documentation",
        link: "knowledge-tree"
    },
    {
        id: 7,
        icon: <SettingsIcon/>,
        text: "Settings",
        link: "settings"
    }
]