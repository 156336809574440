import { useEffect, lazy } from 'react';
import { createContext, useContext, useState } from 'react';
import { Routes, Route } from "react-router-dom";
import { MsalProvider, useMsal } from '@azure/msal-react';
import { EventType } from '@azure/msal-browser';

import useFetchWithMsal from './hooks/useFetchWithMsal';
import { b2cPolicies, protectedResources } from './authConfig';
import { compareIssuingPolicy } from './utils/claimUtils';

import { PageLayout } from './components/layout/PageLayout';
import { TodoList } from './pages/TodoList';
import { ModuleList } from './pages/ModuleList';
import { ModuleEdit } from './pages/ModuleEdit';

import { Module601 } from './pages/Module601';
import { Module602 } from './pages/Module602';
import { Settings } from './pages/Settings';
import { AdminTools } from './pages/AdminTools';

import { ClientList } from './pages/ClientList';
import { TeamList } from './pages/TeamList';
// import { TeamForm } from './pages/TeamForm';

// import { MessageList } from './pages/MessageList';

import { KnowledgeTree } from './pages/KnowledgeTree';
import { Placeholder } from './pages/Placeholder';
import { ServiceRequests } from './pages/ServiceRequests';
import { ServiceRequest } from './pages/ServiceRequest';

import { TaskList } from './pages/TaskList';
import { TaskEdit } from './pages/TaskEdit';

import { WorkflowList } from './pages/WorkflowList';

import { AppList } from './pages/AppList';

import { Home } from './pages/Home';

import './styles/App.css';
// import './styles/old.css';

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";

import SideNav from './components/layout/SideNav';
import { Container } from 'react-bootstrap';
import { ToastContainer, toast, Slide } from 'react-toastify';
import { PushNotification } from './components/PushNotification';

import Auth from './components/GoogleApiAuth';
import Redirect from './components/GoogleApiRedirect';


export const DataContext = createContext();


const Pages = () => {
    /**
     * useMsal is hook that returns the PublicClientApplication instance,
     * an array of all accounts currently signed in and an inProgress value
     * that tells you what msal is currently doing. For more, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
     */
    const { instance } = useMsal();

    useEffect(() => {
        const callbackId = instance.addEventCallback((event) => {
            if (
                (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
                event.payload.account
            ) {
                /**
                 * For the purpose of setting an active account for UI update, we want to consider only the auth
                 * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
                 * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
                 * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
                 */
                if (compareIssuingPolicy(event.payload.idTokenClaims, b2cPolicies.names.editProfile)) {
                    // retrieve the account from initial sing-in to the app
                    const originalSignInAccount = instance
                        .getAllAccounts()
                        .find(
                            (account) =>
                                account.idTokenClaims.oid === event.payload.idTokenClaims.oid &&
                                account.idTokenClaims.sub === event.payload.idTokenClaims.sub && 
                                compareIssuingPolicy(account.idTokenClaims, b2cPolicies.names.signUpSignIn)        
                        );

                    let signUpSignInFlowRequest = {
                        authority: b2cPolicies.authorities.signUpSignIn.authority,
                        account: originalSignInAccount,
                    };

                    // silently login again with the signUpSignIn policy
                    instance.ssoSilent(signUpSignInFlowRequest);
                }

                /**
                 * Below we are checking if the user is returning from the reset password flow.
                 * If so, we will ask the user to reauthenticate with their new password.
                 * If you do not want this behavior and prefer your users to stay signed in instead,
                 * you can replace the code below with the same pattern used for handling the return from
                 * profile edit flow
                 */
                if (compareIssuingPolicy(event.payload.idTokenClaims, b2cPolicies.names.forgotPassword)) {
                    let signUpSignInFlowRequest = {
                        authority: b2cPolicies.authorities.signUpSignIn.authority,
                        scopes: [
                            ...protectedResources.apiTodoList.scopes.read,
                            ...protectedResources.apiTodoList.scopes.write,
                        ],
                    };
                    instance.loginRedirect(signUpSignInFlowRequest);
                }
            }

            if (event.eventType === EventType.LOGIN_FAILURE) {
                // Check for forgot password error 
                // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
                if (event.error && event.error.errorMessage.includes('AADB2C90118')) {
                    const resetPasswordRequest = {
                        authority: b2cPolicies.authorities.forgotPassword.authority,
                        scopes: [],
                    };
                    instance.loginRedirect(resetPasswordRequest);
                }
            }
        });

        return () => {
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        };
        // eslint-disable-next-line
    }, [instance]);

    return (

        <Routes>
            <Route path="/todolist" element={<TodoList />} />
            <Route path="/moduleedit" element={<ModuleEdit />} />

            <Route path="/module601" element={<Module601 />} />
            <Route path="/module601/:param" element={<Module601 />} />
            <Route path="/auth" element={<Auth />} />
            <Route path="/oauth2callback" element={<Redirect />} />

            <Route path="/module602" element={<Module602 />} />

            <Route path="/settings" element={<Settings />} />
            <Route path="/AdminTools" element={<AdminTools />} />

            <Route path="/clientlist" element={<ClientList />} />

            <Route path="/team-list" element={<TeamList />} />
            {/* <Route path="/team/:mode/:id" element={<TeamForm />} /> */}
              
            {/* <Route path="/message-list" element={<MessageList />} /> */}

            <Route path="/knowledge-tree" element={<KnowledgeTree />} /> 
            {/* <Route path="/knowledge-tree" element={<Placeholder />} /> */}

            <Route path="/service-requests" element={<ServiceRequests />} />
            <Route path="/service-request" element={<ServiceRequest />} />
            <Route path="/service-request/:id" element={<ServiceRequest />} />
            
            {/* <Route path="/task_list/:filterTitle?" element={<TaskList />} /> */}
            {/* <Route path="/task/:mode/:id" element={<TaskEdit />} /> */}
            <Route path="/task-list" element={<TaskList />} />
            <Route path="/task/:id" element={<TaskEdit />} />
            <Route path="/task-new/:id" element={<TaskEdit formMode="new" />} />

            <Route path="/workflow-list" element={<WorkflowList />} />

            <Route path="/knowledge-tree" element={<Placeholder />} />

            <Route path="/applist" element={<AppList />} />

            {/* <Route exact path="/firebase-messaging-sw.js" href="%PUBLIC_URL%/firebase-messaging-sw.js" />
            <Route exact path="/firebase-messaging-sw.js" href="%PUBLIC_URL%/firebase-app-compat.js" />
            <Route exact path="/firebase-messaging-sw.js" href="%PUBLIC_URL%/firebase-messaging-compat.js" /> */}

            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            {/* <Route path="/" element={<ModuleList />} /> */}
        </Routes>

    );
};

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const App = ({ instance }) => {

    const { error, execute } = useFetchWithMsal({
        scopes: protectedResources.apiUpdateFCMToken.scopes.write
    });

    const [data, setData] = useState('Initial Data');
    const [notificationAllowed, setNotificationAllowed] = useState();
    const [isNavCollapsed, setIsNavCollapsed] = useState(false);
    const [deviceFcmToken, setDeviceFcmToken] = useState();
    const [savedFcmToken, setSavedFcmToken] = useState();

    const toggleNavCollapse = () => {
        console.log("isNavCollapsed", isNavCollapsed);
        setIsNavCollapsed(!isNavCollapsed);
    };
    const firebaseConfig = {
        apiKey: "AIzaSyD5Mq1rX16Xb-pqVX8mnO3iQ4Y1WKGFuWE",
        authDomain: "fb-mm-02.firebaseapp.com",
        projectId: "fb-mm-02",
        storageBucket: "fb-mm-02.appspot.com",
        messagingSenderId: "131163204682",
        appId: "1:131163204682:web:5c431b2be7f94e3f66cab3"
      };
    
    const app = initializeApp(firebaseConfig);  
    const messaging = getMessaging(app);

    console.log("messaging", messaging);


    onMessage(messaging, (payload) => {

        console.log("payload", payload);

        const options = {
            type: toast.info,
            className: "fz-green-bg-5",
            transition: Slide,
            hideProgressBar: true,
            autoClose: 9000,
            pauseOnHover: true,
          };
        
          let msgLine1 = payload.notification.title;
          let msgLine2 = payload.notification.body;
          let msgLine3 = "extra data goes here...";
          let msgData = payload.data;
      
          toast.warn(<PushNotification msgLine1={msgLine1} 
            msgLine2={msgLine2} msgLine3={msgLine3} msgData={msgData} />, options);
    });    
    
    useEffect(() => {

        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                console.log('Notification permission granted.');
                setNotificationAllowed('Notification permission granted.');

                // Get registration token. Initially this makes a network call, once retrieved
                // subsequent calls to getToken will return from cache.
                // const messaging = getMessaging();
                getToken(messaging, { vapidKey: 'BFVWnqd3X_bStPWzn2pjTnvxY0j4MgDDhDYgPz0SrBajBOAWC8Z6GB0zZMTA2Wyc2uAShoxuA0BAzrWadVNljpw' }).then((currentToken) => {
                    if (currentToken) {

                        // Send the token to your server and update the UI if necessary
                        console.log('currentToken', currentToken);

                        // Update server with latest token
                        setDeviceFcmToken(currentToken);
                        
                    } else {
                    // Show permission request UI
                    console.log('No registration token available. Request permission to generate one.');
                    // ...
                    }
                }).catch((err) => {
                    console.log('An error occurred while retrieving token. ', err);
                    // ...
                });
            } else {
                console.log('Notification permission NOT granted.');
                setNotificationAllowed('Notification permission NOT granted.');
            }
        });

    }, [messaging]);


    useEffect(() => {

        if (deviceFcmToken) {
            // const sendParams = {
            //     FCMToken: deviceFcmToken,
            // };

            // console.log("sendParams - FCMToken", sendParams);

            // // Update server with latest token
            // execute("POST", protectedResources.apiUpdateFCMToken.endpoint, sendParams)
            //     .then((response) => {
            //         console.log('apiUpdateFCMToken', response);
            //     }
            // );                    

            localStorage.setItem("FCMToken", deviceFcmToken);
            setSavedFcmToken(deviceFcmToken);
        }

    }, [deviceFcmToken]);


    useEffect(() => {
        let fcmToken = localStorage.getItem("FCMToken");

        if (fcmToken) {
            const sendParams = {
                FCMToken: fcmToken,
            };

            console.log("sendParams - FCMToken", fcmToken);

            // Update server with latest token
            execute("POST", protectedResources.apiUpdateFCMToken.endpoint, sendParams)
                .then((response) => {
                    console.log('apiUpdateFCMToken', response);
                }
            );  
        }
    }, [savedFcmToken]);


    return (
        <MsalProvider instance={instance}>
            <DataContext.Provider value={{ data, setData }}>
                <PageLayout className="App" instance={instance} data={data}
                            isNavClosed={isNavCollapsed} notificationAllowed={notificationAllowed}>
                    {/* {notificationAllowed} */}
                    <SideNav className="navigation" toggleNavCollapse={toggleNavCollapse} />
                    <Container className={`main-content ${isNavCollapsed ? 'expanded' : ''}`}>
                        <div>
                            <Pages data={data} />
                        </div>
                        <ToastContainer />
                    </Container>
                </PageLayout>
            </DataContext.Provider>
        </MsalProvider>
    );
};

export default App;
