// File Transfer Service (ScopeId 601)

import React, { useState, useRef, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { nanoid } from "nanoid";
import ListGroup from "react-bootstrap/ListGroup";
import {useLocation, useNavigate, useParams, useHistory} from 'react-router-dom';

import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { protectedResources } from "../authConfig";

import Dropdown from 'react-bootstrap/Dropdown';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import { Button } from "react-bootstrap";

import SettingsIcon from '@mui/icons-material/Settings';
import { CircularProgress } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { FormGroup, Input, Label } from 'reactstrap';
import { Container, Row, Col } from 'reactstrap';
import { ProgressBar } from 'react-bootstrap';


export const ModuleView601 = (props) => {

    const { instance } = useMsal();
    const account = instance.getActiveAccount();
    const { param } = useParams();
    const navigate = useNavigate();

    const { error, execute } = useFetchWithMsal({
        scopes: protectedResources.apiModuleList.scopes.write
    });

    const [clientId, setClientId] = useState();
    const [clientSecret, setClientSecret] = useState();
    const [redirectUri, setRedirectUri] = useState();
    const [refreshToken, setRefreshToken] = useState();


    const [allFiles, setAllFiles] = useState(props.todoListData);
    const [files, setFiles] = useState([]);
    const [fileVersions, setFileVersions] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const [fileCount, setFileCount] = useState(null);
    const [versionCount, setVersionCount] = useState(null);
    const [selectedFileName, setSelectedFileName] = useState(null);

    const [fileSourceId, setFileSourceId] = useState();
    const [fileSource, setFileSoure] = useState();
    const [sourceFileList, setSourceFileList] = useState([]);
    const [sourceDocDetail, setSourceDocDetail] = useState([]);
    const [destinationPath, setDestinationPath] = useState([]);

    const [processedCount, setProcessedCount] = useState(0);
    const [progress, setProgress] = useState(0);

    const [fileTargetId, setFileTargetId] = useState();
    const [fileTarget, setFileTarget] = useState();
    const [sendTo, setSendTo] = useState('');
    const [sendButton, setSendButton] = useState('secondary');
    const [sendDisable, setSendDisable] = useState(true);
    const [sentCheck, setSentCheck] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const CurrentUrl = () => {
        const location = useLocation();
        const fullUrl = `${window.location.origin}${location.pathname}${location.search}${location.hash}`;
        console.log('fullUrl', fullUrl);
        // return <div>Full URL: {fullUrl}</div>;
        return <div>Relative URL: {location.pathname}</div>;
    };

    const listHeadingRef = useRef(null);

    useEffect(() => {
        // console.log(fileSourceId, fileSource)
        // console.log('sourceFileList', sourceFileList)

        console.log(">>>>", fileSourceId, fileTargetId);

        if (fileSourceId && fileTargetId) {
            setSentCheck(false);
            setSendDisable(false);
            setSendButton('success');
        }

    }, [fileSourceId, fileSource, sourceFileList,
        fileTargetId, fileTarget, sendTo]);


    if (error) {
        return <div>Error: {error.message}</div>;
    }


    const handleSelectSource = (e) => {
        setIsLoading(true);

        execute("GET", protectedResources.apiFileModule601.endpoint).then((response) => {
            console.log(response.data);
            // setSourceFileList(response);


            setSourceFileList(response.data);


            // let dataSubset = response.data.slice(0, 8);
            // setSourceFileList(dataSubset);


            setIsLoading(false);
        });

        setFileSourceId(e.split('||')[0]);
        setFileSoure(e.split('||')[1]);
    }

    const handleSelectTarget = (e) => {
        setFileTargetId(e.split('||')[0]);
        setFileTarget(e.split('||')[1]);
    }

    const handleSelectRecipient = (e) => {
        setSendTo(e);
        if (sendTo !== null) {
            setSendButton('success');
            setSendDisable(false);
            setSentCheck(false);
        }
    }

    const getFolderContents = (e) => {
        console.log(fileTargetId, fileTarget);
    }


    const handleSelectItem = (event) => {
        console.log('handleSelectItem...');
    };


    //--****--
    const handleSelectFile = async (event) => {

        const selectedIndex = event.target.selectedIndex;

        if (selectedIndex > -1) {  // Check if an option is selected

            const selectedDoc = sourceFileList[selectedIndex];
            // console.log("selectedDoc", selectedDoc);

            setSourceDocDetail(selectedDoc);

            let docPath = await buildDestinationPath(selectedDoc);
            console.log('docPath', docPath);

            setDestinationPath(docPath);
        }

    }

    const buildDestinationPath = (docDetail) => {

        // Parse doc detail into target folder structure
        let docPath = '';
        const fundType = docDetail.allocations[0].custom_fields[0].value;
        docPath += fundType + ' / ';

        const fundFolder = fundType + ' - ' + docDetail.allocations[0].validated_data.fundName + ' - ' + docDetail.allocations[0].validated_data.currency_code;
        docPath += fundFolder + ' / ';

        const docType = docDetail.document_type;
        let mappedDocType = getMappedDocType(docType);

        let docTypeLetters = extractDocTypeLetters(mappedDocType);
        docPath += mappedDocType + ' / ';

        let docYear = docDetail.allocations[0].data_date;
        docYear = docYear.split('-')[0];
        docPath += docYear + ' / ';

        let docDate = docDetail.allocations[0].data_date;
        const date = new Date(docDate);
        const day = String(date.getDate()+1).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = String(date.getFullYear()).slice(-2);
        docDate = day + month + year;

        let fileName =  docDetail.allocations[0].validated_data.fundName +  "_" +docTypeLetters + "_" + docDate + docDetail.file_type;

        docPath += fileName;

        return docPath;
    }


    function extractDocTypeLetters(str) {
        const regex = /\b\w/g;
        const capitalizedLetters = str.match(regex).map(letter => letter.toUpperCase());
        return capitalizedLetters.join('');        
    }
      

    function getMappedDocType(docType) {
        const array = [
            ['Account Statement', 'Capital account statements'],
            ['Capital Call Notice', 'Capital calls'],
            ['Capital Distribution Notice', 'Distribution notices'],
        ];

        for (let i = 0; i < array.length; i++) {
            if (array[i][0] === docType) {
                return array[i][1];
            }
        }

        return '----';
    }


    //****
    const handleGetToken = (e) => {
        console.log('handleGetToken', refreshToken);
        navigate('/auth');
    }


    const delay = ms => new Promise(res => setTimeout(res, ms));


    const handleTransfer = async () => {

        try {

            setInProgress(true);
            setSendDisable(true);
            setSendButton('secondary');
            let transferType = "";

            switch(fileTargetId) {
                case "5051":
                    transferType = "canoe-box";
                    break;
                case "5052":
                    transferType = "canoe-google";
                    break;
            }

            console.log('transferType', transferType);
            setProcessedCount(0);

            // Transfer file(s) via backend
            const sendParams = {
                transferType: transferType,
                fileSourceId: fileSourceId,
                fileTargetId: fileTargetId
            };

            execute("POST", protectedResources.apiTransferFilesModule601.endpoint, sendParams)
                .then((response) => {
            
                    console.log(response);
                    // const progressValue = parseFloat(response.data);
                    // setProgress(progressValue);

                    setInProgress(false);
                    setSentCheck(true);
                }
            );        


            // for (const selectedDoc of sourceFileList) {
            //     // console.log(`id: ${selectedDoc.id}, document_type: ${selectedDoc.document_type}`);


            //     const selectedKey = selectedDoc.id; // Access key using index
            //     // console.log("selectedFileId", selectedKey);

            //     setSourceDocDetail(selectedDoc);

            //     // Parse doc detail into target folder structure
            //     let docPath = '';
            //     const fundType = selectedDoc.allocations[0].custom_fields[0].value;
            //     docPath += fundType + ' / ';

            //     const fundFolder = fundType + ' - ' + selectedDoc.allocations[0].validated_data.fundName + ' - ' + selectedDoc.allocations[0].validated_data.currency_code;
            //     docPath += fundFolder + ' / ';

            //     const docType = selectedDoc.document_type;
            //     docPath += docType + ' / ';

            //     let docYear = selectedDoc.allocations[0].data_date;
            //     docYear = docYear.split('-')[0];
            //     docPath += docYear;

            //     setDestinationPath(docPath);


            //     // Simulate API processing delay
            //     await delay(1000);


            //     // Transfer file via backend
            //     const sendParams = {
            //         transferType: transferType,
            //         fileSourceId: fileSourceId,
            //         fileTargetId: fileTargetId,
            //         fileId: selectedKey,
            //         fileSize: selectedDoc.file_size,
            //         destinationPath: docPath,               
            //     };

            //     execute("POST", protectedResources.apiTransferFileModule601.endpoint, sendParams)
            //         .then((response) => {
            //             // console.log(response);
            //             setSentCheck(true);
            //             setInProgress(false);
            //         }
            //     );

                
            //     setProcessedCount((prevCount) => prevCount + 1); // Update count after processing
            // }        

            // setInProgress(false);
            // setSendDisable(false);


            if (error) {
                return <div>Error: {error.message}</div>;
            }
        
            return <>done</>;

        } catch (error) {
            // Handle any errors that occur during the API call
            console.error('Error:', error);
        }
    };


    const SourceSettingsDialog = () => {
        const [isOpen, setIsOpen] = useState(false);
        const [inputValue, setInputValue] = useState('');
        const [sourceClientKey, setSourceClientKey] = useState('abcdef123456');
        const [sourceClientSecret, setSourceClientSecret] = useState('abcdef123456');

        const handleOpen = () => {
          setIsOpen(true);
        };
      
        const handleClose = () => {
          setIsOpen(false);
          setInputValue(''); // Clear input value on close
        };
      
        const handleChange = (event) => {
          setInputValue(event.target.value);
        };
      
        const handleChange2 = (event) => {
            setSourceClientKey(event.target.value);
        };

        const handleChange3 = (event) => {
            setSourceClientSecret(event.target.value);
        };

        const handleSubmit = (event) => {
          event.preventDefault();
          // Handle form submission here (e.g., console.log(inputValue))
          handleClose();
        };
      
        return (
          <>
            <SettingsIcon className="settings-button" onClick={handleOpen} />
            {isOpen && (
              <div className="popup-dialog">
                <div className="popup-content">
                  <h4>Source Settings</h4>
                  <form onSubmit={handleSubmit}>
                    <Row>
                        <Col style={{ width: '400px' }}>
                        <FormGroup>
                            <Label style={{ paddingTop: '15px' }}>Canoe Client Key</Label>
                            <Input type="password" name="ClientLabel" id="ClientLabel" value={sourceClientKey} onChange={handleChange2} />
                            <Label style={{ paddingTop: '15px' }}>Canoe Client Secret</Label>
                            <Input type="password" name="ClientLabel" id="ClientLabel" value={sourceClientSecret} onChange={handleChange3} />
                        </FormGroup>     
                        </Col>
                    </Row>
                    <Button type="submit" variant="success" style={{ float: 'right' }}>Save</Button>
                  </form>
                  <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                </div>
              </div>
            )}
          </>
        );
      };


      const TargetSettingsDialog = () => {
        const [isOpen, setIsOpen] = useState(false);
        const [inputValue, setInputValue] = useState('');
        const [targetClientKey, setTargetClientKey] = useState('abcdef123456');
        const [targetClientSecret, setTargetClientSecret] = useState('abcdef123456');

        const handleOpen = () => {
          setIsOpen(true);
        };
      
        const handleClose = () => {
          setIsOpen(false);
          setInputValue(''); // Clear input value on close
        };
      
        const handleChange = (event) => {
          setInputValue(event.target.value);
        };
        
        const handleChange4 = (event) => {
            setTargetClientKey(event.target.value);
        };

        const handleChange5 = (event) => {
            setTargetClientSecret(event.target.value);
        };

        const handleSubmit = (event) => {
          event.preventDefault();
          // Handle form submission here (e.g., console.log(inputValue))
          handleClose();
        };
      
        return (
        <>
            <SettingsIcon className="settings-button" onClick={handleOpen} />
            {isOpen && (
            <div className="popup-dialog">
                <div className="popup-content">
                <h4>Target Settings</h4>
                <form onSubmit={handleSubmit}>
                    <Row>
                        <Col style={{ width: '400px' }}>
                        <FormGroup>
                            <Label style={{ paddingTop: '15px' }}>Google Client Key</Label>
                            <Input type="password" name="ClientLabel" id="ClientLabel" value={targetClientKey} onChange={handleChange4} />
                            <Label style={{ paddingTop: '15px' }}>Google Client Secret</Label>
                            <Input type="password" name="ClientLabel" id="ClientLabel" value={targetClientSecret} onChange={handleChange5} />
                        </FormGroup>     
                        </Col>
                    </Row>
                    <Button type="submit" variant="success" style={{ float: 'right' }}>Save</Button>
                </form>
                <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                </div>
            </div>
            )}
        </>
        );
      };      


      const ScheduleDialog = () => {
        const [isOpen, setIsOpen] = useState(false);
        const [inputValue, setInputValue] = useState('');
        const [scheduleDateTime, setScheduleDateTime] = useState('');
        const [scheduleCronTab, setScheduleCronTab] = useState('0 0 6 1 0 0');

        const handleOpen = () => {
          setIsOpen(true);
        };
      
        const handleClose = () => {
          setIsOpen(false);
          setInputValue(''); // Clear input value on close
        };
      
        const handleChange = (event) => {
          setInputValue(event.target.value);
        };
        
        const handleChange4 = (event) => {
            setScheduleDateTime(event.target.value);
        };

        const handleChange5 = (event) => {
            setScheduleCronTab(event.target.value);
        };

        const handleSubmit = (event) => {
          event.preventDefault();
          // Handle form submission here (e.g., console.log(inputValue))
          handleClose();
        };
      
        return (
        <>
            <span style={{ padding: '5px', paddingRight: '20px' }}>{sourceFileList.length} files</span>
            {/* <span style={{ float: 'right', padding: '5px' }}>{processedCount} of {sourceFileList.length} transferred</span> */}

            { !isLoading && sourceFileList ? null 
                        : <CircularProgress style={{ width: '25px', height: '25px', verticalAlign: 'middle', color: '#198754' }}/> }


            {/* <Button variant="secondary" onClick={handleOpen}
                    style={{ width: '100px', float: 'right' }}>Schedule</Button> */}
            {isOpen && (
            <div className="popup-dialog">
                <div className="popup-content">
                <h4>File Transfer Schedule</h4>
                <form onSubmit={handleSubmit}>
                    <Row>
                        <Col style={{ width: '500px' }}>
                        <FormGroup>
                            <Label style={{ paddingTop: '15px' }}>Select time/date</Label>
                            <Input type="text" value={scheduleDateTime} onChange={handleChange5} />
                            <Label style={{ paddingTop: '15px' }}>CRON Schedule</Label>
                            <Input type="text" value={scheduleCronTab} onChange={handleChange4} />
                        </FormGroup>     
                        </Col>
                    </Row>
                    <Button type="submit" variant="success" style={{ float: 'right' }}>Save</Button>
                </form>
                <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                </div>
            </div>
            )}
        </>
        );
      };     


    return (
        <div className="data-area-div" style={{padding: '40px'}}>

            <Table bordered size="sm">
                <thead>
                    <tr>
                        <th colSpan={2} style={{paddingTop: '10px', paddingBottom: '10px', fontSize: 'large'}}>
                            File Transfer Service
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr>

                        
                        <td style={{width: '200px'}}>

                            <br />
                            <FormGroup>
                                <Label style={{ paddingTop: '15px' }}>Client ID</Label>
                                <Input type="text" value={clientId} onChange={handleSelectItem} />
                                <Label style={{ paddingTop: '15px' }}>Client Secret</Label>
                                <Input type="text" value={clientSecret} onChange={handleSelectItem} />
                                <Label style={{ paddingTop: '15px' }}>Redirect Uri</Label>
                                <Input type="text" value={redirectUri} onChange={handleSelectItem} />
                            </FormGroup>  

                            {/* <CurrentUrl /> */}
                            {/* <p>{param}</p> */}

                            <Label style={{ paddingTop: '15px' }}>Refresh Token</Label>
                            <Form.Control
                                tabIndex="4"
                                as="textarea" rows={4}
                                id="refresh-token"
                                name="text"
                                autoComplete="off"
                                value={ JSON.stringify(param) }
                                onChange={handleSelectItem}
                            />
                            <br />

                            <Button variant="danger" onClick={() => handleGetToken()}>Get Google Token</Button>

                        </td>


                        {/* <td style={{width: '200px'}}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ marginRight: '10px' }}>Source</span>
                                <Dropdown title="File Source" onSelect={handleSelectSource}>
                                    <Dropdown.Toggle variant="secondary" style={{ width: '200px' }}>
                                        {fileSource}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu style={{ width: '200px' }}>
                                        <Dropdown.Item eventKey="5001||Canoe">Canoe</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <div className="settings-button" style={{ paddingRight: '15px', marginLeft: 'auto' }}
                                     title="Source Settings" onClick={ () => console.log('onClick...') }>
                                    <SourceSettingsDialog />
                                </div>
                            </div>
                        </td>
                        <td style={{width: '200px'}}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ marginRight: '10px' }}>Target</span>
                                <Dropdown title="File Target" onSelect={handleSelectTarget}>
                                    <Dropdown.Toggle variant="secondary" style={{ width: '200px' }}>
                                        {fileTarget}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item eventKey="5051||Box">Box</Dropdown.Item>
                                        <Dropdown.Item eventKey="5052||Google Drive">Google Drive</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <div className="settings-button" style={{ paddingRight: '15px', marginLeft: 'auto' }}
                                     title="Target Settings" onClick={ () => console.log('onClick...') }>
                                    <TargetSettingsDialog />
                                </div>
                            </div>
                        </td> */}

                        
                    </tr>

                    {/* <tr>
                        <td>
                            <Form.Select aria-label="Default select" htmlSize="10" disabled={sendDisable}
                                         onChange={handleSelectFile}>

                                { sourceFileList ? 

                                    sourceFileList.map(({name, id}) => {
                                        return (
                                            <option key={id} value={name}>
                                            {name}
                                            </option>
                                        );
                                    })

                                : null }

                            </Form.Select>                       
                        </td>

                        {fileCount}
                        <td>
                            <Form.Select aria-label="Default select" htmlSize="10" disabled="true">
                                {files.map((fileName, index) => {
                                    return (
                                        <option key={index} value={fileName}>
                                        {fileName}
                                        </option>
                                    );
                                })}
                            </Form.Select>                       
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <Form.Control
                                tabIndex="4"
                                as="textarea" rows={8}
                                id="input-doc-data"
                                name="text"
                                autoComplete="off"
                                value={ JSON.stringify(sourceDocDetail) }
                                onChange={handleSelectItem}
                            />
                        </td>
                        <td>
                            <Form.Control
                                tabIndex="4"
                                as="textarea" rows={8}
                                id="destination-doc-path"
                                name="text"
                                autoComplete="off"
                                value={ JSON.stringify(destinationPath) }
                                onChange={handleSelectItem}
                            />
                        </td>
                    </tr>

                    <tr>
                        <td className="td-50pct" style={{ padding: '12px' }}>
                            <ScheduleDialog />
                        </td>
                        <td className="td-50pct custom-border-1" style={{ float: 'right' }}>
                            <Table borderless >
                                <tbody>
                                    <tr>
                                        <td style={{ width: '50px', color: '#198754', paddingTop: '12px', float: 'right' }}>
                                            { inProgress ? <CircularProgress style={{ width: '25px', height: '25px', verticalAlign: 'middle', color: '#198754' }}/> : null }
                                            { sentCheck ? <CheckCircleOutlineIcon style={{ width: '30px', height: '30px' }} /> : null }
                                        </td>
                                        <td style={{ width: '5px' }}></td>
                                        <td>
                                            <Button variant={sendButton} disabled={sendDisable} onClick={handleTransfer}
                                                    style={{ width: '100px', float: 'right', marginLeft: '-40px' }}>Transfer</Button>                  
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </td>
                    </tr> */}

                    {/* <tr>
                        <td>
                            <ProgressBar now={progress} label={`${progress}%`} />
                        </td>
                    </tr> */}


                </tbody>
                
            </Table>
           
        </div>
    );
}
