import React, { useState, useRef, useEffect } from "react";
import {Routes, Route, useNavigate} from 'react-router-dom';
import { useMsal } from "@azure/msal-react";
import { nanoid } from "nanoid";

import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { protectedResources } from "../authConfig";

import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import { Input, Table, Row, Col } from 'reactstrap';
import date from 'date-and-time'; 

function usePrevious(value) {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
}

export const WorkflowListView = (props) => {
    const { instance } = useMsal();
    const account = instance.getActiveAccount();

    const { error, execute } = useFetchWithMsal({
        scopes: protectedResources.apiWorkflowList.scopes.write
    });

    const [workflows, setWorkflows] = useState(props.workflowData);


    let activeAccount;
    let fs_list = [];

    if (instance) {
        activeAccount = instance.getActiveAccount();
        let ext_fs = activeAccount.idTokenClaims.extension_fs;

        if (ext_fs !== null)
            fs_list = ext_fs.split(' ');

        console.log("fs_list", fs_list);
    }

    const workflowList = workflows.length > 0 ? (
        workflows.map(workflow => (
            <WorkflowItem
                key={workflow.ProcessGroupId}
                id={workflow.ProcessGroupId}
                WorkflowId={workflow.WorkflowId}
                WorkflowStatus={workflow.WorkflowStatusTypeId}
                StartDate={ date.format(new Date(workflow.StartDate), 'M/D/YYYY h:mm A', false) }
            />
        ))
    ) : (
        <div className='td-center-h'>No data available</div>
    );

    const listHeadingRef = useRef(null);
    const prevListLength = usePrevious(workflows.length);

    useEffect(() => {
        if (workflows.length - prevListLength === -1) {
            listHeadingRef.current.focus();
        }
    }, [workflows.length, prevListLength]);

    if (error) {
        return <div>Error: {error.message}</div>;
    }
    
    if (fs_list.includes('110') || fs_list.includes('111')) {
        return (
            <div>
                {/* <h2 id="list-heading" tabIndex="-1" ref={listHeadingRef}></h2> */}
                <ListGroup>
                    {workflowList}
                </ListGroup>
            </div>
        );
    } else {
        return <div>Error: Module not available</div>; 
    }
}


export const WorkflowItem = (props) => {

    const navigate = useNavigate();

    const handleClickItem = (id) => {
        console.log('handleClickItem', id);

        // this.props.navigation('/todolist/'+categoryTypeId);
        navigate('/Workflow-edit/'+id);
    }

    let statusColor = '#bababa';

    switch(props.WorkflowStatus) {
        case 888:
            statusColor = "#ffc107";
            break;
        case 1:
            statusColor = "#7da7d1";
            break;
        case 2:
            statusColor = "#b5eba2";
            break;
        case 3:
            statusColor = "#3a9c6c";
            break;
    }

    const viewTemplate = (
        <div onClick={() => handleClickItem(props.id)}
             key={props.id} style={{ paddingTop: '5px', marginBottom: '-20px' }}>
                
            <Table className="workflow-props">
                <tr>
                    <td style={{ width: '60%' }}>
                        <span className="list-label" style={{ fontWeight: 'bold' }}>
                            {props.id} | {props.WorkflowId}  
                        </span>
                    </td>
                    <td style={{ width: '10%' }}>
                        &nbsp;
                    </td>
                    <td style={{ width: '30%' }}>
                        <span className="list-label status-assigned" style={{ backgroundColor: statusColor }}>
                            {props.WorkflowStatus}
                        </span>
                    </td>                            
                </tr>
                <tr>
                    <td>
                        <span className="list-label">
                            {props.Subject}
                        </span>
                    </td>
                    <td>
                        <span className="list-label" />
                    </td>
                    <td className="td-right-h">
                        <span className="list-label" />
                    </td>                            
                </tr>
                <tr>
                    <td>
                        <span className="list-label">
                            {props.RequestBy}
                        </span>
                    </td>
                    <td>
                        <span className="list-label" />
                    </td>
                    <td className="td-right-h">
                        <span className="list-label">
                            {props.StartDate}
                        </span>
                    </td>                            
                </tr>
            </Table>
        </div>
    );

    return <ListGroup.Item>{viewTemplate}</ListGroup.Item>;
}